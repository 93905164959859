import axios from "axios";
import { fetchUrl } from "../fetchurl";

export const sendCheckout = async (body, token) => {
  return axios
    .post(fetchUrl + "/onboarding/initiate-checkout", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => err);
};
