import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "./redux/shop/shop.actions";
import ScrollToTop from "./logic/ScrolltoTop";
import ReactPixel from "react-facebook-pixel";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

import Size from "./routes/Size.jsx";
import Print from "./routes/Print/Print";
import Name from "./routes/Account/Name/Name";
import Address from "./routes/Account/Address/Address";
import Checkout from "./routes/Checkout/Checkout";
import Doorsturen from "./routes/Doorsturen";
import Bedankt from "./routes/Bedankt";
import Abandoned from "./routes/Abandoned";
import Frequency from "./routes/Frequency/Frequency";

function App() {
  const gtmParams = {
    id: "GTM-WFJBLD7",
    dataLayer: { ecommerce: null },
    dataLayerName: "dataLayerOnboarding",
  };

  if (typeof fbq === "undefined") {
    ReactPixel.init("203515240925478", { debug: false, autoConfig: false });
  }

  const dispatch = useDispatch();

  const products = useSelector((state) => state.shop.products);

  const newUser = useSelector((state) => state.user);

  useEffect(() => {
    if (products === null) {
      dispatch(fetchProducts(null));
    }
  }, [dispatch, products]);

  return (
    <GTMProvider state={gtmParams}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Size />
          </Route>
          <Route exact path="/frequentie">
            <ScrollToTop />
            {newUser && newUser.size ? <Frequency /> : <Redirect to="/" />}
          </Route>
          <Route exact path="/print">
            <ScrollToTop />
            {newUser && newUser.size ? (
              <Print />
            ) : (
              <Redirect to="/frequentie" />
            )}
          </Route>
          <Route exact path="/gegevens/">
            <Redirect to="/gegevens/name" />
          </Route>
          <Route exact path="/gegevens/name">
            <ScrollToTop />
            {newUser && newUser?.line_items ? (
              <Name />
            ) : (
              <Redirect to="/print" />
            )}
          </Route>
          <Route exact path="/gegevens/address">
            <ScrollToTop />
            {newUser && newUser?.first_name ? (
              <Address />
            ) : !newUser.first_name ? (
              <Redirect to="/gegevens/name" />
            ) : (
              <Redirect to="/print" />
            )}
          </Route>
          <Route exact path="/afronden">
            <ScrollToTop />
            <Checkout />
          </Route>
          <Route path="/doorverwijzing">
            <Doorsturen />
          </Route>
          <Route path="/welkom-bij-underdog">
            <Bedankt />
          </Route>
          <Route path="/herstel-winkelwagen">
            <Abandoned />
          </Route>
        </Switch>
      </BrowserRouter>
    </GTMProvider>
  );
}

export default App;
