import React from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { postNewUser } from "../redux/user/user.actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronLeft } from "react-bootstrap-icons";
import UDLogo from "../assets/Udzwart.svg";
import ReactPixel from "react-facebook-pixel";

const Size = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const newUser = useSelector((state) => state.user);

  ReactPixel.pageView();

  const search = useLocation().search;
  const size = new URLSearchParams(search).get("size");

  if (
    size === "S" ||
    size === "s" ||
    size === "M" ||
    size === "m" ||
    size === "l" ||
    size === "L" ||
    size === "XL" ||
    size === "xl" ||
    size === "XXL" ||
    size === "xxl"
  ) {
    dispatch(postNewUser({ size: size }));

    history.push("/frequentie");
  }

  const handleClick = (e) => {
    if (newUser === undefined) {
      toast.error("Er is geen maat gekozen!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      dispatch(postNewUser({ size: e.target.value }));
      history.push("/frequentie");
    }
  };

  const handleBack = () => {
    window.location.replace("https://underdog.nl/");
  };

  return (
    <Wrapper>
      <>
        <Back size="25" onClick={handleBack} role="button" />
        <div className="col-12 pt-3 text-center">
          <a href="https://underdog.nl">
            <object
              data={UDLogo}
              type="image/svg+xml"
              aria-label="Logo-UD-Black"
              width="175px"
            ></object>
          </a>
        </div>
        <Inside>
          <div className="container pt-4">
            <div className="row">
              <div className="col-12">
                <Title className="h2 text-center">Wat is je maat?</Title>
                <p className="p text-center mt-2 text-muted">
                  Neem bij twijfel een maat groter.
                </p>
              </div>
              <div className="offset-lg-2 col-12 col-lg-8 mt-md-4">
                <div className="row text-center">
                  <div className="col-12 col-sm-6">
                    <Label>
                      <input
                        type="radio"
                        className=""
                        name="type"
                        value="S"
                        onChange={(e) => handleClick(e)}
                      />
                      <p className="h4 mt-sm-3">S</p>
                      <p>Broekmaat 28"</p>
                    </Label>
                  </div>
                  <div className="col-12 col-sm-6">
                    <Label>
                      <input
                        type="radio"
                        className=""
                        name="type"
                        value="M"
                        onChange={(e) => handleClick(e)}
                      />
                      <p className="h4 mt-sm-3">M</p>
                      <p>Broekmaat 29-30"</p>
                    </Label>
                  </div>
                  <div className="col-12 mt-sm-2" />
                  <div className="col-12 col-sm-6">
                    <Label>
                      <input
                        type="radio"
                        className=""
                        name="type"
                        value="L"
                        onChange={(e) => handleClick(e)}
                      />
                      <p className="h4 mt-sm-3">L</p>
                      <p>Broekmaat 30-32"</p>
                    </Label>
                  </div>
                  <div className="col-12 col-sm-6">
                    <Label>
                      <input
                        type="radio"
                        className=""
                        name="type"
                        value="XL"
                        onChange={(e) => handleClick(e)}
                      />
                      <p className="h4 mt-sm-3">XL</p>
                      <p>Broekmaat 33-34"</p>
                    </Label>
                  </div>
                  <div className="col-12 col-sm-6">
                    <Label>
                      <input
                        type="radio"
                        className=""
                        name="type"
                        value="XXL"
                        onChange={(e) => handleClick(e)}
                      />
                      <p className="h4 mt-sm-3">XXL</p>
                      <p>Broekmaat 34-36"</p>
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StyledContainer />
        </Inside>
      </>
    </Wrapper>
  );
};

export default Size;

const Wrapper = styled.div`
  min-height: 100vh;
`;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;

const Back = styled(ChevronLeft)`
  position: absolute;
  left: 10px;
  top: 16px;
  z-index: 20;
`;

const Label = styled.label`
  padding: 1.3rem;
  width: 100%;
  background-color: #f5f5f5;
  border: 3px solid white;
  border-radius: 20px;

  img {
    height: 250px;
  }

  input {
    display: none;
  }

  :active {
    border: solid 3px #007bff;
  }

  @media (max-width: 800px) {
    padding: 1rem;
  }

  @media (max-width: 500px) {
    padding: 1rem 0 0;
  }
`;

const Inside = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 75vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

const Title = styled.h2`
  @media (max-width: 800px) {
    font-size: 1.9rem;
  }

  @media (max-width: 500px) {
    font-size: 1.6rem;
  }
`;
