import { React } from "react";
import UDLogo from "../assets/Udzwart.svg";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Dog from "../assets/UD_dog.svg";
import { ArrowRightShort } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import ReactPixel from "react-facebook-pixel";

const Bedankt = () => {
  const newUser = useSelector((state) => state.user.succesUser);

  const handleClick = () => {
    window.location.replace("https://mijn.underdog.nl/");
  };

  ReactPixel.pageView();

  return (
    <Wrapper>
      <Header>
        <div className="col-12 pt-3 pb-3 bg-white text-center">
          <a href="https://underdog.nl">
            <object
              data={UDLogo}
              type="image/svg+xml"
              aria-label="Logo-UD-Black"
              width="175px"
            ></object>
          </a>
        </div>
      </Header>
      <div className="container">
        <div className="row">
          <div className="ml-auto mr-auto col-md-6 col-12 col-md-10">
            <div className="ml-auto mr-auto text-center mb-5 pt-5">
              <img src={Dog} width="110px" alt="dog" />
            </div>
            <div className="col-12 col-lg-7 ml-auto mr-auto">
              <p className="h2 text-center font-weight-bold">
                Welkom bij Underdog
                <span className="text-capitalize">
                  {", " + newUser?.first_name || null}
                </span>
                ! 🎉
              </p>
              <p className="p text-center mt-4">
                <b>We hebben je bestelling succesvol ontvangen.</b>
              </p>
              <p className="p text-center mt-4 mb-4">
                Wij gaan snel aan de slag om je bestelling in te pakken. Je
                wordt per email op de hoogte gehouden wanneer deze is meegegeven
                aan PostNL. Hij valt binnen 3 á 4 werkdagen op je mat.
              </p>
            </div>
            {newUser?.line_items ? (
              <Invoice className="col-12 col-sm-10 col-md-8 col-lg-6 ml-auto mr-auto text-center bg-white mb-3 pt-4 mt-5 pl-0 pr-0">
                <Title className="h5 pb-3">Je eerste boxershort</Title>
                {newUser.line_items.map((product, index) => {
                  return (
                    <Items className="row pr-3 pl-2">
                      <div key={index} className="col-3 pt-3 pb-3 col-lg-3 ">
                        <img
                          src={product.image}
                          alt={product.print}
                          width="100%"
                        />
                      </div>
                      <div
                        key={index}
                        className="col-5 col-md-5 text-left col-lg-5 my-auto"
                      >
                        <span>Wijde boxershort</span>
                        <br />
                        <span className="font-weight-light">
                          {product.print} - {product.size}
                        </span>
                      </div>
                      <div className="col-4 col-md-4 col-lg-4 pt-3 pb-3 text-right my-auto">
                        <div className="col-12 my-auto pr-0 pt-4 text-right">
                          <p className="h6 mb-4 ">€0,00</p>
                        </div>
                      </div>
                    </Items>
                  );
                })}
                <div className="row pl-3 pr-3 pt-2">
                  <div className="col-8 pt-2 pb-2 text-left mt-1">
                    <p className="h6">Totaal</p>
                  </div>
                  <div className="col-4 pt-2 pb-2 text-right">
                    <p>
                      <span>€0,00</span>
                    </p>
                  </div>
                </div>
              </Invoice>
            ) : null}
            <div className="ml-auto mr-auto text-center mt-5 mb-3">
              <Button onClick={() => handleClick()}>
                Naar je account
                <ArrowRightShort />
              </Button>
            </div>
            <div className="col-11 col-md-6 ml-auto mr-auto">
              <p className="p text-center mt-4">
                Hier kan je jouw vorige prints vinden, je membership aanpassen
                of je frequentie veranderen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Bedankt;

const Wrapper = styled.div`
  background-color: #f5f5f5;
  min-height: 95vh;
  padding-bottom: 3rem;
`;

const Header = styled.div`
  height: 50px;
  background-color: white;
`;

const Invoice = styled.div`
  color: black;
  background-color: #2b3a48;
  text-align: center;

  :after {
    content: " ";
    display: block;
    position: absolute;
    bottom: -30px;
    left: 0px;
    width: 100%;
    height: 36px;
    background: linear-gradient(white 0%, transparent 0%),
      linear-gradient(135deg, white 33.33%, transparent 33.33%) 0 0%,
      #f5f5f5 linear-gradient(45deg, #f5f5f5 33.33%, white 33.33%) 0 0%;
    background-repeat: repeat-x;
    background-size: 0px 100%, 9px 27px, 9px 27px;
  }
`;

const Items = styled.div`
  border-bottom: 0.0625rem solid rgb(232, 238, 240);
`;

const Title = styled.p`
  border-bottom: 0.0625rem solid rgb(232, 238, 240);
`;
