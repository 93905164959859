import { shopActionTypes } from "./shop.types";
import { getProducts } from "../../queries/getProducts";

export const fetchProducts = () => async (dispatch, getState) => {
  dispatch({
    type: shopActionTypes.FETCH_PRODUCTS_REQUEST,
  });
  try {
    const product = await getProducts();

    dispatch({
      type: shopActionTypes.FETCH_PRODUCTS_SUCCES,
      payload: product,
    });
  } catch (error) {
    dispatch({
      type: shopActionTypes.FETCH_PRODUCTS_FAILURE,
      payload: error.data,
    });
  }
};
