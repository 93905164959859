import { useState } from "react";
import Form from "react-bootstrap/Form";
import { postNewUser } from "../../../../redux/user/user.actions";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import { emailCheck } from "../../../../queries/EmailCheck";
import findErrors from "./findErrors";
import { useHistory } from "react-router-dom";

const NameForm = () => {
  const dispatch = useDispatch();
  const newUser = useSelector((state) => state.user);
  const history = useHistory();

  const [form, setForm] = useState({
    first_name: newUser.first_name || "",
    last_name: newUser.last_name || "",
    email: newUser.email || "",
    password: newUser.password || "",
  });

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!newUser.errors[field])
      dispatch(postNewUser({ errors: { ...newUser.errors, [field]: null } }));
  };

  const handleSumbit = async (e) => {
    e.preventDefault();
    const newErrors = findErrors(form);

    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      dispatch(postNewUser({ errors: newErrors }));
    } else {
      const emailUser = { email: form.email.toLowerCase() };

      const result = await emailCheck(emailUser);

      if (result.status === 200) {
        dispatch(postNewUser(form));
        history.push("/gegevens/address");
      } else {
        const errorBody = {
          email: "Je email is al bekend bij ons.",
        };
        dispatch(postNewUser({ errors: errorBody }));
      }
    }
  };

  return (
    <>
      <p className="h3 text-center text-lg-left">Je Gegevens</p>
      <Form
        className="mt-4 needs-validation"
        noValidate
        onSubmit={(e) => handleSumbit(e)}
      >
        <Form.Group>
          <p className="mb-1">Voornaam</p>
          <Form.Control
            style={{ backgroundColor: "#f5f5f5" }}
            defaultValue={newUser?.first_name || ""}
            type="text"
            isInvalid={!!newUser?.errors?.first_name}
            onChange={(e) => setField("first_name", e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {newUser.errors?.first_name || null}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <p className="mb-1">Achternaam</p>
          <Form.Control
            style={{ backgroundColor: "#f5f5f5" }}
            type="text"
            defaultValue={newUser?.last_name || ""}
            isInvalid={!!newUser.errors?.last_name}
            onChange={(e) => setField("last_name", e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {newUser.errors?.last_name}
          </Form.Control.Feedback>
        </Form.Group>
        <hr className="mt-4 mb-4" />

        <Form.Group>
          <p className="mb-1">E-mailadres</p>
          <Form.Control
            style={{ backgroundColor: "#f5f5f5" }}
            type="email"
            defaultValue={newUser?.email || ""}
            isInvalid={!!newUser.errors?.email}
            autoComplete="username"
            onChange={(e) => setField("email", e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            <p className="w-50">{newUser.errors?.email}</p>
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <p className="mb-1">Wachtwoord</p>
          <Form.Control
            style={{ backgroundColor: "#f5f5f5" }}
            type="password"
            defaultValue={newUser?.password || ""}
            autoComplete="current-password"
            isInvalid={!!newUser.errors?.password}
            onChange={(e) => setField("password", e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {newUser.errors?.password}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="mt-4 text-center">
          <p>
            <a
              className="w-100 text-secondary "
              href="https://mijn.underdog.nl"
            >
              <u>Ik heb al een account</u>
            </a>
          </p>
          {newUser.isLoading === false ? (
            <Button className="btn btn-primary" type="submit">
              Verder
            </Button>
          ) : (
            <Button className="btn btn-primary">
              <StyledSpinner animation="border" variant="white" />
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default NameForm;

const Button = styled.button`
  padding: 0.6rem 2rem;
  border-radius: 30px;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;
