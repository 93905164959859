const findErrors = (form) => {
  const { postcode, houseNumber } = form;
  const newErrors = {};

  var match = postcode.match(/([A-Za-z])/g);

  if (!postcode || postcode === "") newErrors.postcode = "Vul je postcode in!";
  else if (postcode.length < 6) newErrors.postcode = "Je postcode is te kort";
  else if (match && match.length !== 2)
    newErrors.postcode = "Je postcode bevat te veel/weinig letters";
  else if (postcode.slice(0, -2) >= 1000 && postcode.slice(0, -2) >= 9999)
    newErrors.postcode = "Je postcode bevat te veel/weinig cijfers";
  if (!houseNumber || houseNumber === "")
    newErrors.houseNumber = "Vul alleen je huisnummer in!";
  else if (isNaN(houseNumber)) {
    newErrors.houseNumber = "Je huisnummer bevat letters";
  }

  return newErrors;
};

export default findErrors;
