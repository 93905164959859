import React from "react";
import styled from "styled-components";
import { Check } from "react-bootstrap-icons";

const USPS = () => {
  return (
    <USP className="row pb-5">
      <div className="col-12 mt-4 mb-2 p-0">
        <ColoredCheck />{" "}
        <span className="p">Altijd een unieke print op je deurmat</span>
      </div>
      <div className="col-12 mt-2 mb-2 p-0">
        <ColoredCheck />{" "}
        <span className="p">€5 memberkorting op je boxershorts</span>
      </div>
      <div className="col-12 mt-2 mb-2 p-0">
        <ColoredCheck />{" "}
        <span className="p">Premium topkwaliteit & pasvorm.</span>
      </div>
    </USP>
  );
};

export default USPS;

const USP = styled.div`
  margin: 1rem 0;
`;

const ColoredCheck = styled(Check)`
  color: #01b67a;
  font-size: 30px;
  vertical-align: -9px;
`;
