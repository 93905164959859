import React from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { postNewUser } from "../../redux/user/user.actions";
import { useHistory } from "react-router-dom";

const Form = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const newUser = useSelector((state) => state.user);

  const frequency = 0;

  const setFrequency = (frequentie) => {
    const body = {
      frequency: frequentie,
    };

    dispatch(postNewUser(body));

    history.push("/print");
  };

  return (
    <div>
      <div>
        <form className="row  ml-auto mr-auto">
          <div className="col-12 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "1"
                  ? "border-dark font-weight-bold border-4 col-12"
                  : "col-12"
              }
            >
              Maandelijks
              <input
                type="radio"
                value="1"
                name="adjust"
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
          <div className="col-12 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "2"
                  ? "border-dark font-weight-bold border-4 col-12"
                  : "col-12"
              }
            >
              Elke 2 maanden
              <input
                type="radio"
                value="2"
                name="adjust"
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
          <div className="col-12 pt-2 pb-2 pl-0">
            <Label
              className={
                frequency === "3"
                  ? "border-dark font-weight-bold border-4 col-12"
                  : "col-12"
              }
            >
              Elke 3 maanden
              <input
                type="radio"
                value="3"
                name="adjust"
                onChange={(e) => setFrequency(e.target.value)}
              />
            </Label>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;

const Label = styled.label`
  text-align: center;
  padding: 1.25rem 1rem 1rem 1rem;
  border: 2px solid #d8e0e3;
  width: 100%;
  height: 100%;

  :hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }

  & :nth-child(2) {
    border-bottom: 0px;
  }

  & > input[type="radio"] {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }

  & > div > div > input[type="radio"] {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }

  & > div > div > span {
    margin-left: -5px;
  }
`;

const Button = styled.button``;
