const findErrors = (form) => {
  const { first_name, last_name, password, email } = form;
  const newErrors = {};

  if (!first_name || first_name === "") {
    newErrors.first_name = "Vul je voornaam in!";
  } else if (first_name.length < 2) {
    newErrors.first_name = "Je naam moet minimaal 2 letters bevatten";
  }

  if (!last_name || last_name === "") {
    newErrors.last_name = "Vul je achternaam in!";
  } else if (last_name.length < 2) {
    newErrors.last_name = "Je achternaam moet minimaal 2 letters bevatten";
  }

  if (!password || password === "") {
    newErrors.password = "Vul je wachtwoord in!";
  } else if (password.length < 6) {
    newErrors.password = "Je wachtwoord moet minimaal 6 letters bevatten";
  }

  if (!email || email === "") {
    newErrors.email = "Vul je emailadres in!";
  } else if (!email.includes("@")) {
    newErrors.email = "Je hebt geen emailadres ingevuld";
  } else if (!email.includes(".")) {
    newErrors.email = "Je hebt geen emailadres ingevuld";
  } else if (email.length < 5) {
    newErrors.password = "Je email moet minimaal 5 letters bevatten";
  }

  let lastAtPos = email.lastIndexOf("@");
  let lastDotPos = email.lastIndexOf(".");

  if (
    !(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      email.indexOf("@@") === -1 &&
      lastDotPos > 2 &&
      email.length - lastDotPos > 2
    )
  ) {
    newErrors.email = "Je email is verkeerd!";
  }

  return newErrors;
};

export default findErrors;
