import axios from "axios";
import { fetchUrl } from "../fetchurl";

export const sendCheckPayment = async (body) => {
  return axios
    .post(fetchUrl + "/onboarding/check-payment/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => err);
};
