import axios from "axios";
import { fetchUrl } from "../fetchurl";

export const checkAndFetchAddress = async (body, token) => {
  return axios
    .post(fetchUrl + "/onboarding/address-check", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .catch((err) => err);
};
