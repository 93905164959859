import { useState } from "react";
import Form from "react-bootstrap/Form";
import { postNewUser } from "../../../../redux/user/user.actions";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import findErrors from "./findErrors";
import { checkAndFetchAddress } from "../../../../queries/checkAndFetchAddress";

const AddressForm = () => {
  const dispatch = useDispatch();
  const newUser = useSelector((state) => state.user);

  const [form, setForm] = useState({
    postcode: newUser.postcode || "",
    houseNumber: newUser.houseNumber || "",
    houseAddition: newUser.houseAddition || "",
  });

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!newUser.errors[field])
      dispatch(postNewUser({ errors: { ...newUser.errors, [field]: null } }));
  };

  const handleSumbit = async (e) => {
    e.preventDefault();
    const newErrors = findErrors(form);

    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      dispatch(postNewUser({ errors: newErrors }));
    } else {
      const bodyForm = {
        postcode: form.postcode.replace(/\s/g, "").toUpperCase(),
        houseNumber: form.houseNumber,
        houseAddition: form.houseAddition,
      };

      const addressResult = await checkAndFetchAddress(bodyForm);

      if (addressResult.status === 200) {
        const addressBody = {
          address_1: addressResult.data.street,
          city: addressResult.data.city,
          postcode: addressResult.data.postalCode
            .replace(/\s/g, "")
            .toUpperCase(),
          houseNumber: form.houseNumber,
          houseAddition: form.houseAddition,
          country: "Nederland",
          addressPopup: true,
          errors: { postcode: null },
        };
        dispatch(postNewUser(addressBody));
      } else if (addressResult.status === 201) {
        const errorBody = {
          postcode:
            "Je adres is al bekend bij ons. Log in of contact de klantenservice",
        };
        dispatch(postNewUser({ errors: errorBody }));
      } else {
        const errorBody = {
          postcode: "Je adres kan niet gevonden worden",
        };
        dispatch(postNewUser({ errors: errorBody }));
      }
    }
  };

  return (
    <>
      <p className="h3 text-center text-lg-left">Je Gegevens</p>
      <Form
        className="mt-4 needs-validation"
        noValidate
        onSubmit={(e) => handleSumbit(e)}
      >
        <Form.Group>
          <p className="mb-1">Postcode*</p>
          <Form.Control
            style={{ backgroundColor: "#f5f5f5" }}
            defaultValue={newUser?.postcode || ""}
            type="text"
            isInvalid={!!newUser?.errors?.postcode}
            onChange={(e) => setField("postcode", e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {newUser.errors?.postcode || null}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="row">
          <Form.Group className="col-6">
            <p className="mb-1">Huisnummer*</p>
            <Form.Control
              style={{ backgroundColor: "#f5f5f5" }}
              type="number"
              defaultValue={newUser?.houseNumber || ""}
              isInvalid={!!newUser.errors?.houseNumber}
              onChange={(e) => setField("houseNumber", e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {newUser.errors.houseNumber}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-6">
            <p className="mb-1">Toevoeging</p>
            <Form.Control
              style={{ backgroundColor: "#f5f5f5" }}
              type="text"
              defaultValue={newUser?.houseAddition || ""}
              isInvalid={!!newUser.errors?.houseAddition}
              onChange={(e) => setField("houseAddition", e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              <p className="w-50">{newUser.errors.houseAddition}</p>
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <Form.Group>
          <p className="mb-1">Land</p>
          <Form.Control
            style={{ backgroundColor: "#d1d1d1" }}
            type="text"
            defaultValue="Nederland"
            disabled
          />
          <Form.Control.Feedback type="invalid">
            <p className="w-50">{newUser.errors.houseAddition}</p>
          </Form.Control.Feedback>
        </Form.Group>
        <div className="mt-4 text-center">
          {newUser.isLoading === false ? (
            <Button className="btn btn-primary" type="submit">
              Verder
            </Button>
          ) : (
            <Button className="btn btn-primary">
              <StyledSpinner animation="border" variant="white" />
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default AddressForm;

const Button = styled.button`
  padding: 0.6rem 2rem;
  border-radius: 30px;
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;
