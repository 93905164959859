import bgImage from "../../assets/DSC09471-1.jpg";
import styled from "styled-components";

const RightSide = () => {
  return (
    <BackgroundImage>
      <Progress className="offset-1">
        <div className="row">
          <div className="col-4 ">
            <NotActiveProgress className="h6 text-muted">1</NotActiveProgress>
          </div>
          <div className="col-8 ">
            <NotActiveText className="h6 text-muted">Bestelling</NotActiveText>
          </div>
          <div className="col-4 mt-3 mb-3">
            <NotActiveProgress className="h6 text-muted ">2</NotActiveProgress>
          </div>
          <div className="col-8 my-auto mt-3 mb-3">
            <NotActiveText className="h6 mt-2 text-muted">
              Gegevens
            </NotActiveText>
          </div>
          <div className="col-4 text-muted">
            <ActiveProgress className="h5">3</ActiveProgress>
          </div>
          <div className="col-8 ">
            <p className="h5 mt-2">Betaling</p>
          </div>
        </div>
      </Progress>
    </BackgroundImage>
  );
};

export default RightSide;

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

const Progress = styled.div`
  background-color: white;
  margin-top: 5rem;
  max-width: 275px;
  padding: 2rem;
`;

const ActiveProgress = styled.p`
  border-radius: 40px;
  background-color: #007bff;
  padding: 7px;
  width: 40px;
  color: white;
  text-align: center;
  margin: 0 auto;
`;

const NotActiveProgress = styled.p`
  border-radius: 40px;
  border: 1px solid grey;
  padding: 7px;
  width: 30px;
  font-size: 12px;

  text-align: center;
  margin: 0 auto;
`;

const NotActiveText = styled.p`
  font-size: 14px;
  margin-top: 6px;
`;
