export const userActionTypes = {
  POST_NEW_USER: "POST_NEW_USER",

  FETCH_TOKEN_REQUEST: "FETCH_TOKEN_REQUEST",
  FETCH_TOKEN_SUCCES: "FETCH_TOKEN_SUCCES",
  FETCH_TOKEN_FAILURE: "FETCH_TOKEN_FAILURE",

  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_SUCCES: "FETCH_USER_SUCCES",
  FETCH_USER_FAILURE: "FETCH_USER_FAILURE",

  FETCH_USER_AND_DATA_REQUEST: "FETCH_USER_AND_DATA_REQUEST",
  FETCH_USER_AND_DATA_SUCCES: "FETCH_USER_AND_DATA_SUCCES",
  FETCH_USER_AND_DATA_FAILURE: "FETCH_USER_AND_DATA_FAILURE",

  PHONE_CHECK_REQUEST: "PHONE_CHECK_REQUEST",
  PHONE_CHECK_SUCCES: "PHONE_CHECK_SUCCES",
  PHONE_CHECK_FAILURE: "PHONE_CHECK_FAILURE",

  POST_PHONE_TAB: "POST_PHONE_TAB",

  CREATE_BACKUP_REQUEST: "CREATE_BACKUP_REQUEST",
  CREATE_BACKUP_SUCCES: "CREATE_BACKUP_SUCCES",
  CREATE_BACKUP_FAILURE: "CREATE_BACKUP_FAILURE",

  INITIATE_CHECKOUT_REQUEST: "INITIATE_CHECKOUT_REQUEST",
  INITIATE_CHECKOUT_SUCCES: "INITIATE_CHECKOUT_SUCCES",
  INITIATE_CHECKOUT_FAILURE: "INITIATE_CHECKOUT_FAILURE",

  PAYMENT_CHECK_REQUEST: "PAYMENT_CHECK_REQUEST",
  PAYMENT_CHECK_SUCCES: "PAYMENT_CHECK_SUCCES",
  PAYMENT_CHECK_FAILURE: "PAYMENT_CHECK_FAILURE",
  PAYMENT_CHECK_FAILURE_IBAN: "PAYMENT_CHECK_FAILURE_IBAN",

  RESTORE_CART_REQUEST: "RESTORE_CART_REQUEST",
  RESTORE_CART_SUCCES: "RESTORE_CART_SUCCES",
  RESTORE_CART_FAILURE: "RESTORE_CART_FAILURE",

  RESET_CHECK_COUNT: "RESET_CHECK_COUNT",
  PAYMENT_SUCCES: "PAYMENT_SUCCES",
};
