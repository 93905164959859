import React from "react";
import styled from "styled-components";
import ConditionsText from "./ConditionsText";

const ConditionsPopup = ({ setPopup }) => {
  return (
    <Wrapper onClick={() => setPopup(false)}>
      <Inside>
        <div>
          <Cross role="button" onClick={() => setPopup(false)}>
            X
          </Cross>
          <div className="text-center">
            <span className="h3">Algemene voorwaarden</span>
          </div>
          <Text>
            <ConditionsText />
          </Text>
        </div>
      </Inside>
    </Wrapper>
  );
};

export default ConditionsPopup;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 20%);
  z-index: 100;
  padding: 2rem;
`;

const Inside = styled.div`
  z-index: 999;
  top: 5vh;
  max-width: 700px;
  max-height: 80vh;
  margin: 0 auto;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  position: relative;
`;

const Cross = styled.div`
  font-size: 20px;
  position: absolute;
  top: 15px;
  -webkit-appearance: none;
  right: 20px;
`;

const Text = styled.div`
  overflow: scroll;
  max-height: 60vh;
  margin-top: 2rem;
  overflow-x: hidden;
  position: relative;
`;
