import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronLeft } from "react-bootstrap-icons";
import UDLogo from "../../assets/Udzwart.svg";
import { useHistory } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import Form from "./Form";

const Frequency = () => {
  ReactPixel.pageView();
  const history = useHistory();

  const handleBack = () => {
    history.push("/");
  };

  return (
    <Wrapper>
      <Back size="25" onClick={handleBack} role="button" />
      <div className="col-12 pt-3 text-center">
        <a href="https://underdog.nl">
          <object
            data={UDLogo}
            type="image/svg+xml"
            aria-label="Logo-UD-Black"
            width="175px"
          ></object>
        </a>
      </div>
      <Inside>
        <div className="container pt-4">
          <div className="row">
            <div className="col-12">
              <Title className="h2 text-center">
                Hoevaak wil je een boxershort?
              </Title>
              <p className="p text-center mt-2 text-muted">
                Je kan de frequentie gemakkelijk aanpassen in je account.
              </p>
            </div>
            <div className="offset-lg-1 col-12 col-lg-10 mt-md-4">
              <div className="row text-center">
                <div className="col-12 col-sm-6 ml-auto mt-2 mr-auto">
                  <Form />
                </div>
              </div>
            </div>
          </div>
        </div>
        <StyledContainer />
      </Inside>
    </Wrapper>
  );
};

export default Frequency;

const Wrapper = styled.div`
  height: 100vh;
`;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;

const Loading = styled.div`
  position: relative;
  text-align: center;
`;

const Back = styled(ChevronLeft)`
  position: absolute;
  left: 10px;
  top: 16px;
  z-index: 20;
`;

const Inside = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 75vh;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

const Title = styled.h2`
  @media (max-width: 800px) {
    font-size: 1.9rem;
  }

  @media (max-width: 500px) {
    font-size: 1.6rem;
  }
`;
