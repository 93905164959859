import { useHistory, useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCheckCount,
  paymentCheck,
  paymentSucces,
} from "../redux/user/user.actions";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import ReactPixel from "react-facebook-pixel";

const Doorsturen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const newUser = useSelector((state) => state.user);
  const order = useSelector((state) => state.user);
  const sendDataToGTM = useGTMDispatch();

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  useEffect(() => {
    if (order.voldaan === null || order.voldaan === false) {
      dispatch(paymentCheck(token));
    }

    if (order.voldaan === true) {
      dispatch(resetCheckCount());

      sendDataToGTM({ ecommerce: null });

      sendDataToGTM({
        event: "transaction",
        ecommerce: {
          purchase: {
            actionField: {
              id: newUser?.tracking?.external_id,
              revenue: "0.01",
              tax: "0",
              shipping: "0",
              coupon: "FREE",
              affiliation: "Onboarding",
            },
            products: [
              {
                name: "Wijde boxershort",
                id: 4132,
                price: "0.01",
                brand: "Underdog",
                quantity: 1,
              },
            ],
          },
        },
      });

      ReactPixel.track(
        "Purchase",
        {
          value: "0.01",
          currency: "EUR",
          fn: newUser.first_name,
          ln: newUser.last_name,
          em: newUser.email,
          ct: newUser.city,
          zp: newUser.postcode,
          country: newUser.country,
          external_id: newUser?.tracking?.external_id || null,
          fbp: newUser?.tracking?.fbp || null,
          fbc: newUser?.tracking?.fbc || null,
        },
        { eventID: 400 }
      );

      dispatch(paymentSucces());

      history.push("/welkom-bij-underdog");
    }

    if (order.voldaanCheck === 3) {
      dispatch(resetCheckCount());
      history.push("/afronden");
      toast.warning("Betaling is mislukt. Probeer opnieuw.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

    if (order.voldaanCheck === 100) {
      dispatch(resetCheckCount());
      history.push("/afronden");
      toast.error("Dit IBAN is al bekend bij ons.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }, [order.voldaanCheck, order.voldaan]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container">
      <div className="row">
        <div className="col-6 ml-auto mr-auto text-center mt-5">
          <Spinner animation="border" variant="primary" />
          <p className="h4">Je wordt doorgestuurd...</p>
        </div>
      </div>
    </div>
  );
};

export default Doorsturen;
