import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import UDLogo from "../../../assets/Udzwart.svg";
import bgImage from "../../../assets/DSC09471-1.jpg";
import { useSelector, useDispatch } from "react-redux";
import { ChevronLeft } from "react-bootstrap-icons";
import AddressForm from "./Form/Form";
import { postNewUser } from "../../../redux/user/user.actions";
import HandleButton from "./Form/HandleButton";
import ReactPixel from "react-facebook-pixel";

const Address = () => {
  ReactPixel.pageView();
  const history = useHistory();
  const dispatch = useDispatch();
  const newUser = useSelector((state) => state.user);

  const handleBack = () => {
    history.push("/gegevens/name");
  };

  const handleClose = () => {
    dispatch(postNewUser({ addressPopup: false }));
  };

  const houseAddition = (newUser) => {
    if (isNaN(newUser.houseAddition)) {
      return newUser.houseAddition;
    } else if (newUser.houseAddition.length > 0) {
      const newAddition = "-" + newUser.houseAddition;
      return newAddition;
    }
  };

  const AdditionResult = houseAddition(newUser);

  return (
    <>
      {newUser.addressPopup ? (
        <PopupWrapper className="p-3">
          <PopupInside className="p-4 p-md-5">
            <BackCross
              type="button"
              className="text-white"
              onClick={() => handleClose()}
            >
              X
            </BackCross>
            <span className="h4">Klopt dit adres?</span>
            <div className="mt-3">
              <span className="p">
                {newUser.address_1} {newUser.houseNumber}
                {AdditionResult}
              </span>
              <br />
              <span className="p">
                {newUser.postcode}, {newUser.city}
              </span>
              <br />
              <span className="p">{newUser.country}</span>
            </div>
            <div className="mt-4">
              <HandleButton />
            </div>
          </PopupInside>
        </PopupWrapper>
      ) : null}
      <Back onClick={handleBack} role="button" size="25" />
      <div className="container" id="top">
        <div className="row">
          <div className="offset-lg-1 col-lg-5 col-md-6 col-12">
            <div className="row">
              <div className="col-12 mt-3 text-center ">
                <a href="https://underdog.nl">
                  <object
                    data={UDLogo}
                    type="image/svg+xml"
                    aria-label="Logo-UD-Black"
                    width="175px"
                  ></object>
                </a>
              </div>
              <InsideWrapper className="col-10 col-md-11 ml-md-0 mr-md-0 ml-auto mr-auto">
                <AddressForm />
              </InsideWrapper>
            </div>
          </div>
          <div className="col-md-5 d-none d-md-block">
            <Image>
              <Progress className="offset-1">
                <div className="row">
                  <div className="col-4 ">
                    <NotActiveProgress className="h6 text-muted">
                      1
                    </NotActiveProgress>
                  </div>
                  <div className="col-8 ">
                    <NotActiveText className="h6 text-muted">
                      Bestelling
                    </NotActiveText>
                  </div>
                  <div className="col-4 mt-3 mb-3">
                    <ActiveProgress className="h5">2</ActiveProgress>
                  </div>
                  <div className="col-8 my-auto mt-3 mb-3">
                    <p className="h5 mt-2">Gegevens</p>
                  </div>
                  <div className="col-4 text-muted">
                    <NotActiveProgress className="h6 ">3</NotActiveProgress>
                  </div>
                  <div className="col-8 text-muted">
                    <NotActiveText className="h6" style={{ marginTop: "8px" }}>
                      Betaling
                    </NotActiveText>
                  </div>
                </div>
              </Progress>
            </Image>
          </div>
        </div>
      </div>
      <StyledContainer />
    </>
  );
};

export default Address;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;

const Image = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

const Back = styled(ChevronLeft)`
  position: absolute;
  left: 1%;
  top: 15px;
  z-index: 20;
`;

const Progress = styled.div`
  background-color: white;
  margin-top: 5rem;
  max-width: 275px;
  height: 195px;
  padding: 2rem;
`;

const ActiveProgress = styled.p`
  border-radius: 40px;
  background-color: #007bff;
  padding: 8px;
  width: 40px;
  color: white;
  text-align: center;
  margin: 0 auto;
`;

const NotActiveProgress = styled.p`
  border-radius: 40px;
  border: 1px solid grey;
  padding: 7px;
  width: 30px;
  font-size: 12px;

  text-align: center;
  margin: 0 auto;
`;

const NotActiveText = styled.p`
  font-size: 14px;
  margin-top: 6px;
`;

const InsideWrapper = styled.div`
  margin-top: 2rem;
`;

const PopupWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 20%);
  z-index: 100;
`;

const PopupInside = styled.div`
  position: relative;
  margin: 0 auto;
  top: 10vh;
  background-color: #007bff;
  color: white;
  padding: 3rem 4rem;
  max-width: 400px;
  border-radius: 5px;
`;

const BackCross = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  -webkit-appearance: none;
`;
