import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import UDLogo from "../../../assets/Udzwart.svg";
import bgImage from "../../../assets/DSC09471-1.jpg";
import { ChevronLeft } from "react-bootstrap-icons";
import NameForm from "./Form/Form";
import ReactPixel from "react-facebook-pixel";

const Name = () => {
  ReactPixel.pageView();
  const history = useHistory();

  const handleBack = () => {
    history.push("/print");
  };

  return (
    <>
      <Back onClick={handleBack} role="button" size="25" />
      <div className="container" id="top">
        <div className="row">
          <div className="offset-lg-1 col-lg-5 col-md-6 col-12">
            <div className="row">
              <div className="col-12 mt-3 text-center ">
                <a href="https://underdog.nl">
                  <object
                    data={UDLogo}
                    type="image/svg+xml"
                    aria-label="Logo-UD-Black"
                    width="175px"
                  ></object>
                </a>
              </div>
              <InsideWrapper className="col-10 col-md-11 ml-md-0 mr-md-0 ml-auto mr-auto">
                <NameForm />
              </InsideWrapper>
            </div>
          </div>
          <div className="col-md-5 d-none d-md-block">
            <Image>
              <Progress className="offset-1">
                <div className="row">
                  <div className="col-4 ">
                    <NotActiveProgress className="h6 text-muted">
                      1
                    </NotActiveProgress>
                  </div>
                  <div className="col-8 ">
                    <NotActiveText className="h6 text-muted">
                      Bestelling
                    </NotActiveText>
                  </div>
                  <div className="col-4 mt-3 mb-3">
                    <ActiveProgress className="h5">2</ActiveProgress>
                  </div>
                  <div className="col-8 my-auto mt-3 mb-3">
                    <p className="h5 mt-2">Gegevens</p>
                  </div>
                  <div className="col-4 text-muted">
                    <NotActiveProgress className="h6 ">3</NotActiveProgress>
                  </div>
                  <div className="col-8 text-muted">
                    <NotActiveText className="h6" style={{ marginTop: "8px" }}>
                      Betaling
                    </NotActiveText>
                  </div>
                </div>
              </Progress>
            </Image>
          </div>
        </div>
      </div>
      <StyledContainer />
    </>
  );
};

export default Name;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;

const Image = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
`;

const Back = styled(ChevronLeft)`
  position: absolute;
  left: 1%;
  top: 15px;
  z-index: 20;
`;

const Progress = styled.div`
  background-color: white;
  margin-top: 5rem;
  max-width: 275px;
  height: 195px;
  padding: 2rem;
`;

const ActiveProgress = styled.p`
  border-radius: 40px;
  background-color: #007bff;
  padding: 8px;
  width: 40px;
  color: white;
  text-align: center;
  margin: 0 auto;
`;

const NotActiveProgress = styled.p`
  border-radius: 40px;
  border: 1px solid grey;
  padding: 7px;
  width: 30px;
  font-size: 12px;

  text-align: center;
  margin: 0 auto;
`;

const NotActiveText = styled.p`
  font-size: 14px;
  margin-top: 6px;
`;

const InsideWrapper = styled.div`
  margin-top: 2rem;
`;
