import React from "react";
import styled from "styled-components";

const UsageText = () => {
  return (
    <Wrapper>
      <h4>Proefperiode</h4>
      <h5>1.1. Proefperiode </h5>
      <p>
        Je UNDERDOG membership begint met een proefboxershort, tenzij anders
        wordt aangegeven tijdens het registratieproces. Een uniek account
        bestaat uit een uniek mailadres, uniek bankrekeningnummer, uniek
        telefoonnumer en een uniek adres. Elk bankrekeningnummer, telefoonnumer,
        adres en/of email adres kan één keer gebruikt worden.
      </p>
      <h5>1.2. Extra membership(s)</h5>
      <p>
        Een gebruiker kan via het account een tot en met vijf membership(s)
        aanmaken. De aangemaakte membership(s) gaan mee met de eerstsvolgende
        afschrijving. Deze membership(s) hebben geen recht op een
        proefboxershort.
      </p>
      <h5>1.3. Aanmelding</h5>
      <p>
        Je proefperiode begint vanaf het moment dat jij je succesvol hebt
        aangemeld. In de bevestigingsmail leggen wij de proefperiode nogmaals
        uit. Binnen de proefperiode kun je jouw membership per direct
        stopzetten. Je gaat dan niet mee met de afschrijving van de maand. Na de
        proefperiode hanteren wij een opzegtermijn van één maand. Start je
        proefperiode na de 10e van de maand dan ga je niet mee met de
        afschrijving van dezelfde maand, maar met de volgende maand. Zo heb je
        genoeg tijd om je eerste boxershort te proberen.
      </p>
      <h5>1.4. Aanbieding. </h5>
      <p>
        UNDERDOG bepaalt naar eigen inzicht of een persoon in aanmerking komt
        voor een proefperiode en kan de voorwaarden of duur beperken om misbruik
        van de proefperiode te voorkomen. We behouden ons het recht voor om
        meerdere verkregen boxershorts per account in rekening te brengen indien
        er misbruik is gemaakt.
      </p>
      <h5>1.5. Membershipkosten. </h5>
      <p>
        We schrijven je maandelijkse membershipkosten aan het einde van de
        proefperiode af via je betaalmiddel, tenzij je je membership voor het
        einde van de proefperiode opzegt.
      </p>
      <h5>1.6. Opzegtermijn. </h5>
      <p>
        Het UNDERDOG membership wordt na je proefperiode automatisch voortgezet.
        In je proefperiode kun je de overeenkomt per direct ontbinden via je
        account. In een actief membership heb je geen recht op een
        herroepingstermijn om je overeenkomst per direct te ontbinden en geldt
        de opzegtermijn van één maand.
      </p>
      <h4>Het Membership</h4>
      <h5>2.1. Afschrijving.</h5>
      <p>
        Je UNDERDOG membership wordt elke maand voortgezet totdat het wordt
        opgezegd. Opzeggen gaat via het account dat wordt aangemaakt bij je
        aanmelding. Bij aanmelding verstrek je tevens een betaalmiddel.
        Betaalmiddel betekent een actueel, geldig, geaccepteerd en op de
        aanmeldpagina genoemd betaalmiddel van het betreffende land dat van tijd
        tot tijd kan worden bijgewerkt. Ook gaan wij op deze manier fraude
        tegen. Je geeft ons toestemming om maandelijks, of een door jou andere
        ingestelde frequentie, je membershipkosten voor de komende maand af te
        schrijven via je betaalmiddel, tenzij je je membership opzegt binnen je
        proefperiode.
      </p>
      <h5>2.2. Prints</h5>
      <p>
        Je kan alleen het design van je eerste boxershort kiezen, dit doe je
        tijdens het aanmelden. Vervolgens ontvang je elke maand een nieuwe
        boxershort waarvan je niet weet hoe deze eruit ziet. De boxershort van
        de maand kun je niet omwisselen voor een eerder uitgebrachte boxershort.
      </p>
      <h4>Facturering en opzegging</h4>
      <h5>3.1. Membershipkosten.</h5>
      <p>
        De membershipkosten van UNDERDOG worden maandelijks, of een door andere
        door jou ingestelde periode, afgeschreven via het betaalmiddel dat is
        gekoppeld bij aanmelding.
      </p>
      <h5>3.2. Afschrijving en levering. </h5>
      <p>
        De membershipskosten van UNDERDOG worden afgeschreven voordat de
        boxershort geleverd wordt. De afschrijving hiervan is altijd rond de
        26ste van de maand en de levering de eerste week van de maand erop.
      </p>
      <h5>3.3. Betaalmiddel. </h5>
      <p>
        Om aan UNDERDOG deel te kunnen nemen, moet je een geldig betaalmiddel
        verstrekken. Voor het gebruik en koppelen van sommige betaalmiddelen kan
        de aanbieder bepaalde kosten bij je in rekening brengen, zoals kosten
        voor internationale transacties of andere kosten voor de verwerking van
        je betaalmiddel. Lokale belastingen kunnen per betaalmiddel verschillen.
        Voor meer informatie kun je contact opnemen met de aanbieder van je
        betaalmiddel.
      </p>
      <h5>3.4. Opzegging. </h5>
      <p>
        Je kunt je UNDERDOG membership te allen tijde online opzeggen via je
        account dat je hebt aangemaakt tijdens aanmelding. Nadat je hebt
        opgezegd, behoud je toegang tot UNDERDOG account, hier kun je het
        membership weer activeren. Als je wilt opzeggen, ga je naar je account
        en vervolgens naar het kopje ‘Membership wijzigen’ hier selecteer je de
        maand waarin jouw laatste afschrijving plaatsvindt. Automatisch wordt er
        een bevestigingsmail gestuurd naar het mailadres dat is aangemaakt
        tijdens aanmelding. Wanneer je een openstaande betaling hebt, is het
        niet mogelijk om het membership op te zeggen.
      </p>
      <h5>3.5. Wijziging. </h5>
      <p>
        We kunnen ons membership en de prijs van onze service van tijd tot tijd
        wijzigen. Prijswijzigingen of wijzigingen in ons membership zijn echter
        pas 20 dagen na kennisgeving van toepassing.
      </p>
      <h4>Heractiveren</h4>
      <h5>4.1. Heractiveren. </h5>
      <p>
        Wanneer jij je membership opzegt dan blijft je account bestaan. Hier kun
        je ten alle tijden inloggen om je gegevens in te zien en je account weer
        te activeren. Bij een heractivatie ga je mee met de eerstvolgende
        afschrijving.
      </p>
      <h5>4.2. Actief member. </h5>
      <p>
        Na het opnieuw activeren van je membership ben je weer een actief
        member. Er is dan geen proefperiode en je moet rekening houden met de
        regulieren opzegtermijn van een maand.
      </p>
      <h5>4.3. Annuleren. </h5>
      <p>
        Wil je toch afzien van je heractivatie dan hanteren wij een
        annuleringsperiode van 2 werkdagen. Neem hiervoor contact op via
        support@underdog.nl
      </p>
      <h4>Algemeen</h4>
      <h5>5.1. Leeftijd. </h5>
      <p>
        Je moet minimaal 18 jaar zijn of meerderjarig in je provincie, regio of
        land om member te worden van het afsluiten van een membership bij
        Underdog.
      </p>
      <h5>5.2. Verantwoordelijkheid.</h5>
      <p>
        De member die het UNDERDOG account heeft aangemaakt en via wiens
        betaalmiddel de membershipskosten worden afgeschreven (ook wel de
        gebruiker genoemd), heeft toegang tot en controle over het UNDERDOG
        account. Bovendien is de gebruiker verantwoordelijk voor alle
        activiteiten die plaatsvinden via het account. Je bent verantwoordelijk
        voor de juistheid van de gegevens die je aan ons verstrekt met
        betrekking tot je account en om deze waar nodig bij te werken. We kunnen
        je account beëindigen of tijdelijk blokkeren om jou, UNDERDOG of onze
        partners te beschermen tegen identiteitsdiefstal of andere frauduleuze
        activiteiten.
      </p>
      <h4>Overig</h4>
      <h5>6.1. Toepasselijk recht.</h5>
      <p>
        Deze gebruiksvoorwaarden worden beheerst door en geïnterpreteerd
        overeenkomstig Nederlands recht. Deze voorwaarden vormen geen beperking
        van de dwingende rechtelijke consumentenbescherming die je mogelijk
        toekomt onder de toepasselijke wetgeving in jouw land.
      </p>
      <h5>6.2. Klantenondersteuning.</h5>
      <p>
        Als je meer wilt weten over onze service of als je hulp nodig hebt met
        je account, kun je het onze help en info pagina bezoeken of mailen naar
        onze klantenservice.
      </p>
      <h5>6.3. Van kracht blijvende bepalingen. </h5>
      <p>
        Als een of meer bepalingen van deze Gebruiksvoorwaarden ongeldig,
        onwettig of niet-afdwingbaar worden geacht, blijven de geldigheid,
        wettigheid en afdwingbaarheid van de resterende bepalingen onverminderd
        van kracht.
      </p>
      <h5>6.4. Wijzigingen van de Gebruiksvoorwaarden. </h5>
      <p>
        UNDERDOG kan deze Gebruiksvoorwaarden van tijd tot tijd wijzigen. We
        stellen je minimaal 30 dagen voordat dergelijke wijzigingen op jou van
        toepassing zijn, op de hoogte.
      </p>
      <h5>6.5. Elektronische communicatie. </h5>
      <p>
        We sturen je informatie over je account (zoals betalingsautorisaties,
        facturen, wachtwoordwijzigingen, wijzigingen van je Betaalmiddel(en),
        bevestigingsberichten en kennisgevingen) uitsluitend in elektronische
        vorm, bijvoorbeeld via e-mail naar het e-mailadres dat je hebt opgegeven
        bij je registratie.
      </p>
    </Wrapper>
  );
};

export default UsageText;

const Wrapper = styled.div`
  h4 {
    line-height: 60px;
  }
`;
