import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import UDLogo from "../../assets/Udzwart.svg";
import { ChevronLeft } from "react-bootstrap-icons";
import Sterren from "../../assets/trustpilot.svg";
import Trustpilot from "../../assets/trustpilotLogo.svg";
import "moment/min/locales";
import { useState } from "react";
import ReactPixel from "react-facebook-pixel";
import IDEALLogo from "../../assets/ideal-logo.svg";
import HandleButton from "./HandleButton";
import ConditionsPopup from "./Conditions/ConditionsPopup";
import UsagePopup from "./Usage/UsagePopup";
import USPS from "../../components/USPS/USPS";
import RightSide from "./RightSide";
import moment from "moment";
import { useSelector } from "react-redux";

const Checkout = () => {
  const history = useHistory();

  const newUser = useSelector((state) => state.user);

  ReactPixel.pageView();

  const handleTP = () => {
    window.open("https://nl.trustpilot.com/review/underdog.nl");
  };

  const handleBack = () => {
    history.push("/gegevens/address");
  };

  const leverdatum = () => {
    if (moment().locale("nl").format("D") < 14) {
      return moment()
        .locale("nl")
        .add(newUser.frequency, "months")
        .format("10 MMMM, Y");
    } else {
      return moment()
        .locale("nl")
        .add(parseFloat(newUser.frequency) + 1, "months")
        .format("10 MMMM, Y");
    }
  };

  const [showPopup, setPopup] = useState(false);
  const [terms, setTerms] = useState(false);

  return (
    <Wrapper>
      {showPopup === "conditions" ? (
        <ConditionsPopup setPopup={setPopup} />
      ) : showPopup === "usage" ? (
        <UsagePopup setPopup={setPopup} />
      ) : null}
      <div className="container">
        <Back onClick={handleBack} role="button" size="25" />
        <div className="row">
          <div className="offset-lg-1 col-lg-5 col-md-12">
            <div className="row">
              <div className="col-lg-10 col-12 mt-3 text-center">
                <a href="https://underdog.nl">
                  <object
                    data={UDLogo}
                    type="image/svg+xml"
                    aria-label="Logo-UD-Black"
                    width="175px"
                  ></object>
                </a>
              </div>
              <InsideWrapper className="col-11 ml-auto mr-auto ml-lg-0 mr-lg-0">
                <div>
                  <span className="h3">Overzicht</span>
                </div>
                <div>
                  <Header>
                    <div className="row pt-2">
                      <div className="col-3 col-md-3">
                        <p className="p">Vandaag</p>
                      </div>
                      <div className="col-9 col-md-9">
                        <hr style={{ margin: "0.7rem 0 0 0" }} />
                      </div>
                    </div>
                  </Header>
                  <Item>
                    <div className="row">
                      <div className="col-3">
                        <img src={newUser?.line_items?.[0].image?.src} width="100%" />
                      </div>
                      <div className="col-5 col-md-6">
                        <p className="p mb-1">Wijde Boxershort</p>
                        <p style={{ fontSize: "14px" }}>
                          Print:
                          <span style={{ fontWeight: "700" }}>
                            {" " + newUser.line_items[0].title}
                          </span>
                        </p>
                      </div>
                      <div className="col-4 col-md-3 text-right">14.99</div>
                    </div>
                  </Item>
                  <Header>
                    <div className="row pt-2">
                      <div className="col-5 col-md-3 col-lg-4">
                        <p className="p">Volgende Levering</p>
                      </div>
                      <div className="col">
                        <hr style={{ margin: "0.7rem 0 0 0" }} />
                      </div>
                    </div>
                  </Header>
                  <Item>
                    <div className="row">
                      <div className="col-7 col-md-8">
                        <p className="p mb-1">Wijde boxershort</p>
                        <p style={{ fontSize: "14px", fontWeight: "700" }}>
                          Rond {leverdatum()}
                        </p>
                      </div>
                      <div className="col-5 col-md-4 text-right">14.99</div>
                    </div>
                  </Item>
                  <hr />
                  <Header>
                    <div className="row pt-2">
                      <div className="col-6 col-md-4">
                        <p className="p">Samenvatting</p>
                      </div>
                    </div>
                  </Header>
                  <Item className="pb-3">
                    <div className="row">
                      <div className="col-6 my-auto">
                        <p className="p mb-0">Verzending</p>
                      </div>
                      <div className="col-6 my-auto">
                        <p className="p text-right mb-0">Gratis</p>
                      </div>
                    </div>
                  </Item>

                  <Item className=" pb-3">
                    <div className="row">
                      <div className="col-6 my-auto">
                        <p className="p mb-0">Totaal</p>
                      </div>
                      <div className="col-6 my-auto">
                        <p className="p text-right mb-0">14.99</p>
                      </div>
                    </div>
                  </Item>
                  <Terms className="row ml-0 mr-0">
                    <div className="col-1">
                      <Input
                        type="checkbox"
                        className="form-control"
                        onClick={() => setTerms(!terms)}
                        checked={terms}
                      />
                    </div>
                    <span className="col-11">
                      Ik ben 18+ en ga akkoord met de{" "}
                      <span
                        className="text-primary"
                        onClick={() => setPopup("conditions")}
                        role="button"
                      >
                        <u>algemene voorwaarden</u>
                      </span>{" "}
                      en{" "}
                      <span
                        className="text-primary"
                        onClick={() => setPopup("usage")}
                        role="button"
                      >
                        <u>gebruiksvoorwaarden</u>
                      </span>
                      .
                    </span>
                  </Terms>
                </div>
                <HandleButton terms={terms} />
                <div
                  className="mt-5 mb-5 row"
                  role="button"
                  onClick={() => handleTP()}
                >
                  <div className="col-4">
                    <p className="h6 text-center mt-1">Uitstekend</p>
                  </div>
                  <div className="col-4">
                    <img src={Sterren} alt="trustpilot" />
                  </div>
                  <div className="col-4 text-center">
                    <img src={Trustpilot} alt="trustpilot" width="80px" />
                  </div>
                </div>
                <USPS />
              </InsideWrapper>
            </div>
          </div>
          <div className="col-5">
            <RightSide />
          </div>
        </div>
      </div>
      <StyledContainer />
    </Wrapper>
  );
};

export default Checkout;

const Wrapper = styled.div`
  min-height: 100vh;
`;

const StyledContainer = styled(ToastContainer).attrs()`
  .Toastify__toast--error {
    background-color: #dc3545;
  }
  .Toastify__toast--success {
    background-color: #007bff;
  }
`;

const Back = styled(ChevronLeft)`
  position: absolute;
  left: 1%;
  top: 15px;
  z-index: 20;
`;

const Item = styled.div`
  padding: 0rem 1rem 0 0;
  background-color: white;

  :first-child {
    padding: 1.5rem 1rem 0.5rem 0rem;
    border-top: 0px solid #007bff;
  }
`;

const HR = styled.hr`
  margin: 0px;
  padding: 0px;
`;

const InsideWrapper = styled.div`
  margin-top: 3rem;
`;

const Input = styled.input`
  height: 15px;
  width: 15px;
  margin-top: 4px;
`;

const Terms = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const Header = styled.div`
  color: #acacac;
  font-size: 14px;
  margin-top: 1rem;
`;