import moment from "moment";

const nextPaymentDateGenerator = () => {
  const today = moment().format("DD");

  if (today <= 12) {
    return moment().format("YYYY-MM-26 HH:mm:ss");
  } else {
    return moment().add(1, "months").format("YYYY-MM-26 HH:mm:ss");
  }
};

export default nextPaymentDateGenerator;
