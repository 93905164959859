import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { restoreCart } from "../redux/user/user.actions";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Abandoned = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const newUser = useSelector((state) => state.user);

  const search = useLocation().search;
  const rawtoken = new URLSearchParams(search).get("token");

  useEffect(() => {
    const token = { token: rawtoken };

    if (!newUser.token) {
      dispatch(restoreCart(token));
    } else {
      history.push("/afronden");

      toast.success("Je order is succesvol opgehaald", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }, [newUser, dispatch, history, rawtoken]);

  return <></>;
};

export default Abandoned;
