import axios from "axios";
import { fetchUrl } from "../fetchurl";

export const getProducts = async (id) => {
  const result = await fetchProducts(id);

  const allProducts = result.data;

  return allProducts;
};

const fetchProducts = async (id) => {
  return axios
    .get(fetchUrl + "/shop/all-products/", {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => {
      console.log(err);
    });
};
