import { userActionTypes } from "./user.types.js";

const INITIAL_STATE = {
  size: null,
  isLoading: false,
  voldaan: null,
  voldaanCheck: 1,
  phoneTab: "new",
  phoneChecks: 0,
  errors: {
    first_name: null,
    last_name: null,
    address_1: null,
    houseNumber: null,
    postcode: null,
    country: null,
    email: null,
    password: null,
    city: null,
  },
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActionTypes.POST_NEW_USER:
      return {
        ...state,
        ...action.payload,
      };

    case userActionTypes.PHONE_CHECK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case userActionTypes.PHONE_CHECK_SUCCES:
      return {
        ...state,
        isLoading: false,
      };

    case userActionTypes.PHONE_CHECK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case userActionTypes.POST_PHONE_TAB:
      return {
        ...state,
        phoneTab: action.payload,
      };

    case userActionTypes.CREATE_BACKUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case userActionTypes.CREATE_BACKUP_SUCCES:
      return {
        ...state,
        isLoading: false,
        token: action.payload,
      };

    case userActionTypes.CREATE_BACKUP_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case userActionTypes.INITIATE_CHECKOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case userActionTypes.INITIATE_CHECKOUT_SUCCES:
      return {
        ...state,
        isLoading: false,
        voldaan: false,
        mollie: action.payload,
      };

    case userActionTypes.INITIATE_CHECKOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case userActionTypes.PAYMENT_CHECK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case userActionTypes.PAYMENT_CHECK_SUCCES:
      return {
        ...state,
        isLoading: false,
        voldaan: true,
        phoneVerified: false,
        phoneTab: "new",
      };

    case userActionTypes.PAYMENT_CHECK_FAILURE:
      return {
        ...state,
        isLoading: false,
        voldaanCheck: state.voldaanCheck + 1,
      };

    case userActionTypes.PAYMENT_CHECK_FAILURE_IBAN:
      return {
        ...state,
        isLoading: false,
        voldaanCheck: 100,
      };

    case userActionTypes.RESET_CHECK_COUNT:
      return {
        ...state,
        voldaanCheck: 0,
      };

    case userActionTypes.RESTORE_CART_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case userActionTypes.RESTORE_CART_SUCCES:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };

    case userActionTypes.RESTORE_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case userActionTypes.PAYMENT_SUCCES:
      return {
        isLoading: false,
        size: null,
        voldaan: true,
        voldaanCheck: 1,
        phoneTab: "new",
        phoneChecks: 0,
        errors: {
          first_name: null,
          last_name: null,
          address_1: null,
          houseNumber: null,
          postcode: null,
          country: null,
          email: null,
          password: null,
          city: null,
        },
        succesUser: {
          ...state,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
