import { React, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import HandleButton from "./HandleButton";

const PrintSelector = () => {
  const newUser = useSelector((state) => state.user);
  const products = useSelector((state) => state.shop.products);

  const VariationsArray = products.products.filter(item => item.variants.some((variation) => variation.option1 === newUser.size))


  const [variationChoice, setVariation] = useState(newUser?.line_items?.[0] || VariationsArray[0]);

  console.log(variationChoice)

  return (
    <>
      <div>
        <ProductImage>
          <img
            src={
              variationChoice ? variationChoice?.image?.src : VariationsArray[0]?.image.src
            }
            alt="Chosen product"
          />
        </ProductImage>
         <p className="h5 font-weight-normal mt-3 text-capitalize">
        </p>
        <Form
          className="row m-0 pl-3 pr-3 pb-4"
          style={{ borderRadius: "30px" }}
        >
          {products &&
            VariationsArray.slice(0, 4).map((variation) => (
              <div
                key={variation._id}
                className="col-2  p-0 text-center ml-auto mr-auto"
              >
                <VariationLabel>
                  <input
                    type="radio"
                    name="variation"
                    value={variation}
                    onChange={() => setVariation(variation)}
                    checked={variation.id === variationChoice.id}
                  />
                  <VariationImage
                    src={variation.image.src || null}
                    alt={variation.title}
                    style={
                      variation.id === variationChoice.id
                        ? {
                            border: "1px solid black",
                            padding: "3px ",
                          borderRadius: "30px",
                            backgroundColor: "#f2f2f2",
                          }
                        : {
                            border: "1px solid white",
                          padding: "3px ",
                            backgroundColor: "#f2f2f2",
                          }
                    }
                  />
                  {variation.id === parseInt(variationChoice.id)}
                </VariationLabel>
              </div>
            ))}
        </Form>
        <HandleButton variationChoice={variationChoice} />
      </div>
    </>
  );
};

export default PrintSelector;

const ProductImage = styled.div`
  text-align: center;

  img {
    background-color: #f2f2f2;
    border: 1px solid #e1e1e1;
    max-width: 300px;
  }

  @media (max-width: 600px) {
    img {
      max-width: 250px;
    }
  }

  @media (max-width: 350px) {
    img {
      max-width: 200px;
    }
  }
`;

const VariationLabel = styled.label`
  text-align: center;
  border: 1px solid transparent;
  border-radius: 30px;
  margin: 0 auto;
  input {
    visibility: hidden;
    width: 0px !important;
    height: 0px !important;
  }


`;

const Form = styled.form`
  margin-top: 2rem;
`;

const VariationImage = styled.img`
  max-width: 50px;
  max-height: 50px;
  border-radius: 30px;

  @media (max-width: 600px) {
    max-width: 50px;
    max-height: 50px;
  }

  @media (max-width: 350px) {
    max-width: 45px;
    max-height: 45px;
  }
`;
