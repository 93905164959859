import styled from "styled-components";

const ConditionsText = () => {
  return (
    <Text>
      <h3>Artikel 1 Definities</h3>
      1. Underdog Basics B.V. (hierna: Underdog Basics) is een besloten
      vennootschap die zich ten doel stelt het aanbieden van maandabonnementen
      en eenmalige aankopen van onderbroeken, sokken en andere basics.
      <br />
      <br />
      2. In deze Algemene voorwaarden wordt onder ‘Algemene voorwaarden’
      verstaan: de onderhavige Algemene voorwaarden.
      <br />
      <br />
      3. In deze Algemene voorwaarden wordt onder 'Klant' verstaan: de
      natuurlijke persoon die niet handelt voor doeleinden die verband houden
      met zijn bedrijfs- of beroepsactiviteit en die een Overeenkomst met
      betrekking tot het verrichten van Diensten en het leveren van Producten
      aangaat met Underdog Basics.
      <br />
      <br />
      4. In deze Algemene voorwaarden wordt onder ‘Partijen’, en ieder
      afzonderlijk als ‘Partij’, verstaan: Underdog Basics en/of Klant.
      <br />
      <br />
      5. In deze Algemene voorwaarden wordt onder 'Overeenkomst' verstaan: de
      tussen Underdog Basics en Klant gesloten Overeenkomst, al dan niet
      gesloten in het kader van een georganiseerd systeem voor verkoop of
      dienstverlening op afstand waarbij tot het moment van sluiten van de
      Overeenkomst uitsluitend gebruik wordt gemaakt van een of meer middelen
      voor communicatie op afstand, waarbij Underdog Basics zich jegens Klant
      verbindt Diensten te verrichten en Producten op periodieke basis te
      leveren via abonnementen en Klant zich verbindt hiervoor een prijs te
      betalen. De Overeenkomst komt tot stand door een aanbod van Underdog
      Basics en de aanvaarding daarvan door Klant, hetgeen nader wordt
      gespecificeerd in artikel 4.3 en 4.4 van deze Algemene voorwaarden.
      <br />
      <br />
      6. In deze Algemene voorwaarden wordt onder 'Diensten' verstaan: alle door
      Underdog Basics en/of door haar ingeschakelde derden aan Klant geleverde
      Producten en Diensten, onder andere inhoudende de periodiek geleverde
      onderbroeken en/of sokken, alsmede alle andere door Underdog Basics ten
      behoeve van Klant verrichte werkzaamheden, van welke aard ook, verricht in
      het kader van een opdracht, waaronder begrepen werkzaamheden die niet op
      uitdrukkelijk verzoek van Klant worden verricht.
      <br />
      <br />
      7. In deze Algemene voorwaarden wordt onder ‘Producten’ verstaan: alle
      door Underdog Basics aangeboden Producten, waaronder, maar niet
      uitsluitend, begrepen sokken en ondergoed die op de Website worden
      aangeboden.
      <br />
      <br />
      8. In deze Algemene voorwaarden wordt onder 'Website' verstaan: de Website
      van Underdog Basics, te raadplegen via https://underdog.nl
      <br />
      <br />
      <h3>Artikel 2 Identiteit van Underdog Basics</h3>
      1. Underdog Basics is bij de KvK geregistreerd onder nummer 82979502 en
      draagt btw-identificatienummer NL861451351B01. Underdog Basics is
      gevestigd aan Overtoom 292 (1054JC) te Amsterdam.
      <br />
      <br />
      2. Underdog Basics is per e-mail te bereiken via support@underdog.nl of
      middels de Website https://underdog.nl/hulp-en-info
      <br />
      <br />
      <h3>Artikel 3 Toepasselijkheid van de Algemene voorwaarden</h3>
      1. De Algemene voorwaarden zijn van toepassing op ieder aanbod van
      Underdog Basics en op alle huidige en toekomstige Overeenkomsten,
      leveringen, commerciële relaties en overige rechtsverhoudingen tussen
      Partijen. De Algemene voorwaarden van Klant worden uitdrukkelijk van de
      hand gewezen.
      <br />
      <br />
      2. Afwijkingen van de Algemene voorwaarden zijn slechts geldig indien
      uitdrukkelijk en schriftelijk met Underdog Basics overeengekomen.
      <br />
      <br />
      3. Toepasselijkheid van inkoop- of andere voorwaarden van de Klant worden
      uitdrukkelijk van de hand gewezen, tenzij uitdrukkelijk en schriftelijk
      anders overeengekomen.
      <br />
      <br />
      4. De Algemene voorwaarden zijn ook van toepassing op aanvullende of
      gewijzigde opdrachten van Klant.
      <h3>Artikel 4 Aanbod en totstandkoming Overeenkomst</h3>
      1. Alle aanbiedingen op de Website zijn geheel vrijblijvend, tenzij
      uitdrukkelijk anders aangegeven.
      <br />
      <br />
      2. De Klant kan contact opnemen met Underdog Basics via de Website
      https://www.underdog.nl/ of e-mail support@underdog.nl voor een van de
      aangeboden Diensten of Producten.
      <br />
      <br />
      3. Tussen Underdog Basics en Klant komt de Overeenkomst tot stand op het
      moment dat Klant een aanbieding van Underdog Basics accepteert door middel
      van het invullen van aanmeldformulier op de Website, alsmede het voldoen
      van het verschuldigde bedrag (minimaal 0,01 euro).
      <br />
      <br />
      4. De Overeenkomst bestaat onder meer uit een abonnement met periodieke
      levering van Producten door Underdog Basics aan Klant voor onbepaalde
      tijd, met één maand opzegtermijn.
      <br />
      <br />
      5. Als Underdog Basics een bevestiging naar Klant stuurt, geeft die
      bevestiging richting aan de inhoud en uitleg van de Overeenkomst, onder
      voorbehoud van kennelijke verschrijvingen. Underdog Basics kan niet aan
      zijn aanbod worden gehouden indien Klant redelijkerwijs kan begrijpen dat
      het aanbod, dan wel een onderdeel daarvan, een kennelijke vergissing of
      verschrijving bevat.
      <br />
      <br />
      <h3>Artikel 5 Uitvoering van de Overeenkomst</h3>
      1. Underdog Basics zal zich inspannen de Diensten naar beste inzicht en
      vermogen en Overeenkomstig de eisen van goed vakmanschap, alsmede zoveel
      mogelijk Overeenkomstig de schriftelijk vastgelegde afspraken, uit te
      voeren.
      <br />
      <br />
      2. Underdog Basics heeft het recht bepaalde werkzaamheden te laten
      verrichten door derden.
      <br />
      <br />
      3. De Overeenkomst kan alleen door Underdog Basics worden uitgevoerd
      indien Klant volledige en correcte (contact)gegevens verstrekt aan
      Underdog Basics bij het sluiten van de Overeenkomst. De Klant draagt er
      derhalve zorg voor dat alle gegevens, waarvan Underdog Basics aangeeft dat
      deze noodzakelijk zijn of waarvan de Klant redelijkerwijs behoort te
      begrijpen dat deze noodzakelijk zijn voor het uitvoeren van de
      Overeenkomst, tijdig aan Underdog Basics worden verstrekt.
      <br />
      <br />
      4. De Klant draagt er zorg voor dat Underdog Basics zijn Diensten tijdig
      en deugdelijk kan verrichten. Indien Klant zijn afspraken hieromtrent niet
      nakomt, is hij gehouden de hieruit voortvloeiende schade te vergoeden.
      <br />
      <br />
      5. Indien voor de uitvoering van Diensten of levering van Producten een
      termijn is overeengekomen of opgegeven, dan is dit nimmer een fatale
      termijn. Bij overschrijding van een termijn dient de Klant Underdog Basics
      hiervan schriftelijk in gebreke te stellen. Underdog Basics dient daarbij
      een redelijke termijn te worden geboden om alsnog uitvoering te geven aan
      de Overeenkomst.
      <br />
      <br />
      <h3>Artikel 6 Wijziging en opzegging van de Overeenkomst</h3>
      1. Indien tijdens de uitvoering van de Overeenkomst blijkt dat het voor
      een behoorlijke uitvoering daarvan noodzakelijk is om deze te wijzigen of
      aan te vullen, zullen Underdog Basics en Klant tijdig en in onderling
      overleg tot aanpassing van de Overeenkomst overgaan.
      <br />
      <br />
      2. Indien de Overeenkomst wordt gewijzigd, daaronder begrepen een
      aanvulling, is er sprake van een aanvullende opdracht. Over deze
      aanvullende opdracht zal vooraf een aparte afspraak over de betaling
      worden gemaakt. De prijswijzigingen gerelateerd aan de wijziging in de
      Overeenkomst zullen op de Website beschikbaar worden gesteld.
      <br />
      <br />
      3. Het niet of niet onmiddellijk uitvoeren van de gewijzigde Overeenkomst
      levert geen wanprestatie van Underdog Basics op en is voor de Klant geen
      grond om de Overeenkomst op te zeggen of te ontbinden.
      <br />
      <br />
      4. Wijzigingen in de oorspronkelijk gesloten Overeenkomst tussen Underdog
      Basics en Klant zijn pas geldig vanaf het moment dat deze wijzigingen door
      middel van een aanvullende of gewijzigde Overeenkomst zijn aanvaard door
      beide Partijen. Deze wijziging geschiedt via de Website.
      <br />
      <br />
      5. Klant kan de Overeenkomst die voor dan wel bepaalde tijd, als
      onbepaalde tijd is aangegaan en die strekt tot het periodiek afleveren van
      Producten, te allen tijde opzeggen.
      <br />
      <br />
      6. Elke 21e van de maand wordt de nieuwe bestelling uit de overeenkomst
      geplaatst bij Underdog Basics. Klant dient op te zeggen vóór elke 21e van
      de maand met in acht neming van één maand opzegtermijn.
      <br />
      <br />
      <h3>
        Artikel 8 Opschorting, ontbinding en tussentijdse opzegging van de
        Overeenkomst
      </h3>
      1. Underdog Basics is bevoegd de nakoming van de verplichtingen op te
      schorten of de Overeenkomst te ontbinden, indien de Klant de
      verplichtingen uit de Overeenkomst niet, niet volledig of niet tijdig
      nakomt, dan wel dat Underdog Basics goede grond heeft te vrezen dat de
      Klant in die verplichtingen zal tekortschieten, mits Underdog Basics de
      Klant door middel van een schriftelijke aanmaning in gebreke heeft
      gesteld, waarbij Klant een redelijke termijn voor de nakoming van de
      verplichtingen wordt gesteld, en nakoming binnen deze termijn uitblijft.
      <br />
      <br />
      2. Voorts is Underdog Basics bevoegd de Overeenkomst te ontbinden, onder
      dezelfde voorwaarden als bedoeld in artikel 7.1 van onderhavige
      Overeenkomst, indien zich omstandigheden voordoen welke van dien aard zijn
      dat nakoming van de Overeenkomst onmogelijk is of ongewijzigde
      instandhouding van de Overeenkomst in redelijkheid niet kan worden
      gevergd.
      <br />
      <br />
      3. Indien de Klant zijn uit de Overeenkomst voortvloeiende verplichtingen
      niet nakomt, deze niet-nakoming ontbinding rechtvaardigt en de Klant in
      verzuim is, dan is Underdog Basics gerechtigd de Overeenkomst terstond en
      met directe ingang te ontbinden door middel van een schriftelijke
      verklaring jegens Klant, waarbij de Klant, uit hoofde van wanprestatie,
      tot schadevergoeding of schadeloosstelling is verplicht.
      <br />
      <br />
      <h3>Artikel 9 Herroepingsrecht en retournering Producten</h3>
      1. Klant heeft het recht om een op afstand gesloten Overeenkomst binnen
      veertien dagen kosteloos en zonder opgave van reden te annuleren.
      Annulering wordt doorgegeven via het account.
      <br />
      <br />
      2. Bij de Overeenkomst met betrekking tot het leveren van Diensten begint
      de termijn van het Herroepingsrecht te lopen op de dag waarop de
      Overeenkomst wordt gesloten.
      <br />
      <br />
      3. Bij een Overeenkomst met betrekking tot het leveren van Producten
      begint de termijn van het Herroepingsrecht te lopen voor Klant, of een
      door de Klant aangewezen derde die niet de vervoerder is, op de dag waarop
      hij:
      <br />
      <br />
      1. Het Product heeft ontvangen;
      <br />
      <br />
      2. Het laatste Product heeft ontvangen indien Klant in eenzelfde
      bestelling meerdere Producten heeft besteld die afzonderlijk worden
      geleverd;
      <br />
      <br />
      3. De laatste zending of het laatste onderdeel heeft ontvangen indien de
      levering van het Product bestaat uit verschillende zendingen of
      onderdelen; of
      <br />
      <br />
      4. Het eerste Product heeft ontvangen bij Overeenkomsten voor regelmatige
      levering van Producten gedurende een bepaalde periode.
      <br />
      <br />
      4. Indien Klant binnen de herroepingstermijn als bedoeld in lid 1 van dit
      Artikel gebruikmaakt van het Herroepingsrecht, zal Underdog Basics het
      gehele door Klant betaalde bedrag binnen 14 (zegge: veertien) dagen na
      ontvangst van het Product volledig terugstorten.
      <br />
      <br />
      5. Het herroepingsrecht vervalt indien Klant het de verzegeling van de
      verpakking van het Product heeft verbroken, in verband met hygiëne.
      <br />
      <br />
      6. Na de herroepingstermijn is kosteloze retournering slechts mogelijk
      indien Klant kan bewijzen dat bij de aankomst van de bestelling schade is
      ontstaan aan het Product die niet door Klant is veroorzaakt. Hierbij geldt
      voor Klant een wettelijk bewijsvermoeden, inhoudende dat wanneer het
      Product binnen 6 (zegge: zes) maanden na ontvangst afwijkt van de
      Overeenkomst, wordt vermoed dat het Product bij aflevering niet aan de
      Overeenkomst heeft beantwoord.
      <br />
      <br />
      7. Klant is gedurende de herroepingstermijn als bedoeld in lid 1 van dit
      Artikel gehouden zorgvuldig om te gaan met het ontvangen Product en de
      verpakking. Klant zal het Product slechts gebruiken voor zover dit nodig
      is om het Product te inspecteren. Op grond van regels met betrekking tot
      hygiëne is het Klant niet toegestaan om het Product uit de verpakking te
      halen, dan wel te passen.
      <br />
      <br />
      8. Klant is slechts aansprakelijk voor waardevermindering van het Product
      indien dit het gevolg is van inspectiehandelingen die verder gaan dan de
      in lid 7 van dit Artikel genoemde handelswijze.
      <br />
      <br />
      9. Klant kan het Product retour sturen via een zelfgekozen postbedrijf. De
      kosten van retournering komen voor rekening van Klant.
      <br />
      <br />
      10. Underdog Basics is verplicht om bij een op afstand gesloten
      Overeenkomst aan Klant een retourformulier te verstrekken of deze via de
      Website aan te bieden. Indien Klant de bestelling wenst te retourneren,
      kan Klant het retourformulier invullen.
      <br />
      <br />
      11. Indien Klant op elektronische wijze het retourformulier invult of op
      andere elektronische wijze kenbaar maakt aan Underdog Basics om de
      Overeenkomst op afstand te ontbinden, dan bevestigt Underdog Basics
      onverwijld op een duurzame gegevensdrager de ontvangst hiervan.
      <br />
      <br />
      <h3>Artikel 10 Kosten, honorering en betaling</h3>
      1. Alle genoemde bedragen op de Website zijn in euro's en inclusief BTW,
      tenzij anders vermeld.
      <br />
      2. De bedragen op de website zijn inclusief BTW en exclusief
      verzendkosten, tenzij anders vermeld. Underdog Basics zal het rekenen van
      de verzendkosten duidelijk en tijdig voor het sluiten van de Overeenkomst
      aan Klant vermelden.
      <br />
      <br />
      3. Underdog Basics heeft het recht om kennelijk foutieve verschrijvingen
      in de prijsopgave te herstellen.
      <br />
      <br />
      4. De eerste verificatiebetaling geschiedt via iDeal.
      <br />
      <br />
      5. Na de verificatiebetaling is Underdog Basics gemachtigd om periodiek
      het bedrag af te schrijven van het door Klant verstrekte rekeningnummer.
      <br />
      <br />
      6. De periodieke afschrijving geschiedt na sluiting overeenkomst mits
      elders is gekozen door de Klant.
      <br />
      <br />
      7. Voor bestellingen binnen Nederland vraagt Underdog Basics geen
      verzendkosten.
      <br />
      <br />
      8. De Klant heeft de plicht om onjuistheden in de vermelde of verstrekte
      betaalgegevens onverwijld aan Underdog Basics mede te delen.
      <br />
      <br />
      9. Indien de Klant in gebreke blijft in de tijdige betaling van een
      factuur, dan is de Klant van rechtswege in verzuim, zonder dat daarvoor
      verdere ingebrekestelling is vereist. De Klant is alsdan de wettelijke
      rente verschuldigd. De rente over het opeisbare bedrag zal worden berekend
      vanaf het moment dat de Klant in verzuim is tot het moment van voldoening
      van het volledig verschuldigde bedrag.
      <br />
      <br />
      10. Indien Underdog Basics besluit een vordering wegens niet-betaling van
      één of meer niet-betaalde facturen langs gerechtelijke weg te incasseren,
      is Klant, naast de verschuldigde hoofdsom en de in artikel 9.8 genoemde
      rente, tevens gehouden alle in redelijkheid gemaakte gerechtelijke en
      buitengerechtelijke kosten te vergoeden. De vergoeding van gemaakte
      gerechtelijke en buitengerechtelijke kosten wordt vastgesteld conform het
      alsdan geldende Besluit dat ziet op vergoeding voor buitengerechtelijke
      incassokosten.
      <br />
      <br />
      <h3>Artikel 11 Levering Producten</h3>
      1. Op het moment dat de bestelling door Underdog Basics is ontvangen,
      stuurt Underdog Basics de Producten met inachtneming van de levertermijn
      naar het adres dat Klant aan Underdog Basics kenbaar heeft gemaakt. De
      levertermijn van Underdog Basics voor de eerste bestelling is in beginsel
      3 (zegge: drie) werkdagen. Daarna geschiedt de levering periodiek rond de
      5 tot 10 werkdagen na incassering van het bedrag.
      <br />
      <br />
      2. Indien Underdog Basics de Producten niet binnen de overeengekomen
      levertermijn kan leveren, stelt zij Klant daarvan zo snel mogelijk in
      kennis. Underdog Basics is gerechtigd om in onderling overleg met Klant
      een nieuwe leverdatum overeen te komen, mits Klant hiermee akkoord gaat.
      <br />
      <br />
      3. De Producten zijn voor risico van de koper vanaf het moment dat de
      Producten bij Klant door Underdog Basics of een door hem aangewezen
      vervoerder op het opgegeven afleveradres zijn bezorgd.
      <br />
      <br />
      4. Tenzij schriftelijk anders overeengekomen, vindt verzending plaats
      vanuit Amsterdam.
      <br />
      <br />
      5. Underdog Basics bepaalt zelf de wijze van transport en verpakking.
      Underdog Basics kan ervoor kiezen om de Producten via PostNL of een andere
      postbezorger te laten bezorgen, maar kan ook kiezen om de Producten
      persoonlijk te bezorgen.
      <br />
      <br />
      6. Indien de Producten na het verstrijken van de levertijd voor de Klant
      beschikbaar zijn maar niet door hem worden afgenomen, worden de Producten
      te zijner beschikking opgeslagen voor zijn risico en op zijn kosten,
      ongeacht de reden van niet-afneming.
      <br />
      <br />
      7. Indien het afgeleverde Product wezenlijk niet beantwoordt aan de
      Overeenkomst of ingeval een Product kwijtraakt of beschadigd wordt
      ontvangen, dan wordt gezocht naar een passende oplossing in gezamenlijk
      overleg. Klant heeft in een dergelijk geval jegens Underdog Basics het
      recht om:
      <br />
      <br />
      1. Aflevering van het ontbrekende onderdeel of Product te eisen;
      <br />
      <br />
      2. Herstel van het afgeleverde Product te eisen, mits Underdog Basics
      hieraan redelijkerwijs kan voldoen;
      <br />
      <br />
      3. Vervanging van het Product te eisen, tenzij de afwijking van de
      Overeenkomst te gering is om dit te rechtvaardigen, dan wel het Product na
      het tijdstip dat Klant redelijkerwijs met ontbinding van de Overeenkomst
      rekening moest houden, teniet of achteruit is gegaan doordat Klant niet
      als een zorgvuldig schuldenaar voor het behoud van het Product heeft
      gezorgd;
      <br />
      <br />
      4. De Overeenkomst te ontbinden, tenzij de afwijking van de Overeenkomst
      gezien haar geringe betekenis ontbinding niet rechtvaardigt; of
      <br />
      <br />
      5. De prijs te verminderen in evenredigheid met de mate van afwijking van
      de Overeenkomst.
      <br />
      <br />
      8. De rechten uit lid 7 onder iv en v van dit Artikel ontstaan pas indien
      herstel en vervanging van de afgeleverde Producten onmogelijk is of van
      Underdog Basics niet gevergd kan worden, dan wel indien Underdog Basics
      tekort is geschoten om zijn verplichtingen tot herstel of vervanging van
      de afgeleverde Producten binnen een redelijke termijn na te komen.
      <br />
      <br />
      9. Indien Klant vervanging van het Product eist, zoals bedoeld in lid 7
      onder iii van dit Artikel, en het bestelde Product niet meer leverbaar is,
      is Underdog Basics gerechtigd om aan Klant een soortgelijk Product van
      dezelfde of soortgelijke kwaliteit te leveren.
      <br />
      <h3>Artikel 12 Overmacht</h3>
      1. Onder overmacht wordt verstaan, alle van buiten komende oorzaken,
      buiten wil of toedoen van Underdog Basics, waardoor tijdige, volledige of
      juiste nakoming van de Overeenkomst niet meer mogelijk is.
      <br />
      <br />
      2. Onder overmacht zoals in het vorige lid bedoeld, wordt mede verstaan,
      maar is niet beperkt tot: niet-nakoming van een derde, ziekte van
      personeel van Underdog Basics zelf of een derde, abnormale
      weersomstandigheden, storingen in water- en energieleveringen, stakingen,
      ernstige storingen in de systemen van Underdog Basics, brand,
      overstromingen, natuurrampen, pandemieën, rellen, oorlog of anderszins
      binnenlandse onrusten.
      <br />
      <br />
      3. Indien sprake is van overmacht met als gevolg dat één der Partijen
      tekortschiet in de nakoming van haar verbintenissen uit de Overeenkomst,
      is de andere Partij gerechtigd de Overeenkomst zonder tussenkomst van de
      rechter te ontbinden. In een dergelijk geval zal Underdog Basics overgaan
      tot terugbetaling van eventueel betaalde bedragen, met daarop in mindering
      gebracht alle kosten die Underdog Basics heeft gemaakt met betrekking tot
      de Overeenkomst.
      <br />
      <br />
      <h3>Artikel 13 Garantie</h3>
      1. Underdog Basics biedt een garantie voor de door haar geleverde
      Producten tegen materiaal- en fabricagefouten. De garantie houdt in dat
      Underdog Basics de fouten naar haar beste vermogen zal herstellen en
      indien nodig kosteloos vervangen. Gebreken dienen schriftelijk bij
      Underdog Basics te worden gemeld.
      <br />
      <br />
      2. De garantie is niet van toepassing indien de fouten geheel of
      gedeeltelijk het gevolg zijn van onjuist, ondeskundig, onzorgvuldig
      gebruik, gebruik voor andere dan normale doeleinden of van buiten komende
      oorzaken, waaronder begrepen maar niet uitsluitend brand- of waterschade.
      <br />
      3. De garantie is eveneens niet van toepassing indien de producten door
      derden zijn gewijzigd of worden onderhouden.
      <br />
      <br />
      4. De periode van garantie staat gelijk aan de algemene norm, neergelegd
      in art. 7:17 lid 2 BW, tenzij anders is overeengekomen.
      <br />
      <br />
      5. Indien Underdog Basics herstelwerkzaamheden uitvoert die buiten het in
      dit Artikel genoemde kader vallen, komen de kosten volgens de wet voor
      rekening van Underdog Basics.
      <br />
      <br />
      6. Het in de Algemene voorwaarden bepaalde ter zake van garantie, laat
      onverlet de garantieaanspraken van Klant uit hoofde van de wet.
      <br />
      <br />
      <h3>Artikel 14 Reclame</h3>
      1. Klant is gehouden het geleverde Product op het moment van aflevering,
      althans binnen een zo kort mogelijke termijn, te inspecteren of te doen
      inspecteren. Daarbij behoort Klant te onderzoeken of kwaliteit en
      kwantiteit van het geleverde overeenstemmen met hetgeen in de Overeenkomst
      is bepaald.
      <br />
      <br />
      2. Fouten of onjuistheden die bij een eerste inspectie geconstateerd
      kunnen worden, in acht nemende de eisen van redelijkheid en billijkheid,
      dienen binnen 14 (zegge: veertien) werkdagen na ontvangst van de Producten
      schriftelijk aan Underdog Basics te worden gemeld, met overhandiging van
      het aankoopbewijs, tenzij dit onmogelijk dan wel onredelijk bezwarend is.
      <br />
      <br />
      3. Overige klachten, waaronder begrepen klachten die niet bij een eerste
      inspectie geconstateerd konden worden, dienen uiterlijk binnen één maand
      schriftelijk te worden gemeld bij Underdog Basics Overeenkomstig het
      bepaalde in lid 2 van dit Artikel. Hierbij geldt voor Klant een wettelijk
      bewijsvermoeden die inhoudt dat wanneer het product binnen 6 (zegge: zes)
      maanden na ontvangst afwijkt van de Overeenkomst, wordt vermoed dat het
      product bij aflevering niet aan de Overeenkomst heeft beantwoord.
      <br />
      <br />
      <h3>Artikel 15 Vertrouwelijkheid van gegevens</h3>
      1. Elk der Partijen garandeert dat alle van de andere Partij ontvangen
      gegevens waarvan men weet of dient te weten dat deze van vertrouwelijke
      aard zijn, geheim blijven. De Partij die vertrouwelijke gegevens ontvangt,
      zal deze slechts gebruiken voor het doel waarvoor deze verstrekt zijn.
      Gegevens worden in ieder geval als vertrouwelijk beschouwd indien deze
      door een der Partijen als zodanig zijn aangeduid. Underdog Basics kan
      hieraan niet worden gehouden indien de verstrekking van gegevens aan een
      derde noodzakelijk is ingevolge een rechterlijke uitspraak, een wettelijk
      voorschrift of voor correcte uitvoering van de Overeenkomst.
      <br />
      <br />
      <h3>Artikel 16 Intellectuele eigendom</h3>
      1. Underdog Basics behoudt zich de rechten en bevoegdheden toe die hem
      toekomen op grond van de Auteurswet.
      <br />
      <br />
      2. Klant garandeert dat geen rechten van derden zich verzetten tegen
      beschikbaarstelling aan Underdog Basics van gegevens. Klant zal Underdog
      Basics vrijwaren tegen elke actie die gebaseerd is op de bewering dat
      zodanig beschikbaar stellen, gebruiken, bewerken, installeren of
      incorporeren inbreuk maakt op enig recht van derden.
      <br />
      <br />
      <h3>Artikel 17 Klachtenregeling</h3>
      1. Indien Klant een klacht heeft dient Klant dit schriftelijk te zenden
      aan support@underdog.nl. De klacht wordt, indien redelijkerwijs mogelijk,
      3 (zegge: twee) werkdagen na ontvangst van de klacht door Underdog Basics
      in behandeling genomen, waarna Klant zo snel mogelijk een inhoudelijke
      reactie ontvangt.
      <br />
      <br />
      2. Klant heeft ook de mogelijkheid om een klacht voor te leggen aan de
      Geschillencommissie via het Europees ODR Platform, te vinden op de website
      http://ec.europa.eu/consumers/odr/.
      <br />
      <br />
      <h3>Artikel 18 Toepasselijk recht en bevoegde rechter</h3>
      1. Op de rechtsverhouding(en) tussen Underdog Basics en haar Klant is
      Nederlands recht van toepassing.
      <br />
      <br />
      2. Alle geschillen die tussen Underdog Basics en Klant mochten ontstaan,
      worden voorgelegd aan de bevoegde rechtbank in het arrondissement waar
      Klant zijn of haar woonplaats heeft.
      <br />
      <br />
      Indien blijkt dat een bepaling of meerdere bepalingen in deze Algemene
      voorwaarden nietig zijn, wordt de geldigheid van de overige bepalingen van
      deze Algemene voorwaarden alsmede de gehele Overeenkomst niet aangetast.
      Partijen zullen zich in een dergelijk geval inspannen de nietige bepaling
      te vervangen voor een nieuwe, geldige bepaling die zo dicht mogelijk de
      ongeldige bepaling benadert binnen de strekking van de oorspronkelijke
      Algemene voorwaarden
    </Text>
  );
};

export default ConditionsText;

const Text = styled.div`
  h3 {
    font-size: 20px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;
