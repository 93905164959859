import React from "react";
import styled from "styled-components";
import ReactPixel from "react-facebook-pixel";
import { useDispatch } from "react-redux";
import { postNewUser } from "../../redux/user/user.actions";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import moment from "moment";
import { useHistory } from "react-router-dom";
import nextPaymentDateGenerator from "../../logic/nextPaymentDateGenerator";
import { sendAddToCart } from "../../queries/sendAddToCart";

const HandleButton = ({ variationChoice }) => {
  const dispatch = useDispatch();
  const sendDataToGTM = useGTMDispatch();
  const history = useHistory();

  const nextPaymentDate = nextPaymentDateGenerator();

  const handleClick = async () => {
    const fbpValue = getCookie("_fbp");
    const fbcCookie = getCookie("_fbc");
    const external_id = Math.floor(Math.random() * 100000) + 1;

    ReactPixel.track(
      "AddToCart",
      {
        value: "13.99",
        currency: "EUR",
        external_id: external_id || null,
        fbp: fbpValue || null,
        fbc: fbcCookie || null,
      },
      { eventID: 100 }
    );

    sendDataToGTM({
      event: "addToCart",
      ecommerce: {
        actionField: { step: 1 },
        currencyCode: "EUR",
        add: {
          products: [
            {
              name: "Wijde boxershort",
              id: 4132,
              price: "13.99",
              brand: "Underdog",
              variant: String(variationChoice.name),
              quantity: 1,
            },
          ],
        },
      },
    });

    dispatch(
      postNewUser({
        tracking: {
          external_id: external_id,
          fbp: fbpValue,
          fbc: fbcCookie,
        },
        next_payment_date: nextPaymentDate,
        start_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        total: 13.99,
        line_items: [
          {
            product_id: 4132,
            ...variationChoice,
          },
        ],
      })
    );

    const addToCartBody = {
      external_id: external_id,
      fbp: fbpValue,
      fbc: fbcCookie,
    };

    await sendAddToCart(addToCartBody);

    history.push("/gegevens/name");
  };

  return (
    <Button className="btn btn-primary" onClick={handleClick}>
      Verder
    </Button>
  );
};

export default HandleButton;

const Button = styled.button`
  padding: 0.6rem 1.5rem;
  border-radius: 30px;
`;

function getCookie(cname) {
  const cookies = Object.fromEntries(
    document.cookie.split(/; /).map((c) => {
      const [key, v] = c.split("=", 2);
      return [key, decodeURIComponent(v)];
    })
  );
  return cookies[cname] || "";
}
