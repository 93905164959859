import axios from "axios";
import { fetchUrl } from "../../fetchurl";
import { userActionTypes } from "./user.types";
import { SendPhoneNumber } from "../../queries/phoneNumberCheck";
import { sendBackup } from "../../queries/sendBackup";
import { sendCheckout } from "../../queries/sendCheckout";
import { sendCheckPayment } from "../../queries/sendPaymentCheck";
import { fetchLostCart } from "../../queries/fetchLostCart";
import { toast } from "react-toastify";

export const postNewUser = (body) => (dispatch, getState) => {
  dispatch({
    type: userActionTypes.POST_NEW_USER,
    payload: body,
  });
};

export const addToCart = (body) => (dispatch, getState) => {
  axios
    .post(fetchUrl + "/login/add-to-cart", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((err) => err);
};

export const phoneNumberCheck = (body) => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.PHONE_CHECK_REQUEST,
  });

  const user = getState().user;

  const verifiedBody = {
    phoneVerified: false,
  };

  dispatch({
    type: userActionTypes.POST_NEW_USER,
    payload: verifiedBody,
  });

  const result = await SendPhoneNumber(body);

  if (result.status === 200) {
    dispatch({
      type: userActionTypes.PHONE_CHECK_SUCCES,
    });

    const body = {
      phoneChecks: user.phoneChecks + 1,
    };

    dispatch({
      type: userActionTypes.POST_NEW_USER,
      payload: body,
    });

    dispatch(changePhoneTab("validate"));
  } else {
    dispatch({
      type: userActionTypes.PHONE_CHECK_FAILURE,
    });
    toast.error("Je telefoonnummer is al bekend", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const createBackup = (body) => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.CREATE_BACKUP_REQUEST,
  });

  const result = await sendBackup(body);

  if (result.status === 200) {
    dispatch({
      type: userActionTypes.CREATE_BACKUP_SUCCES,
      payload: result.data,
    });
  } else {
    dispatch({
      type: userActionTypes.CREATE_BACKUP_FAILURE,
    });
  }
};

export const initiateCheckout = (body) => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.INITIATE_CHECKOUT_REQUEST,
  });

  const result = await sendCheckout(body);

  if (result.data) {
    window.location.href = result.data._links.checkout.href;

    if (result.status === 200) {
      dispatch({
        type: userActionTypes.INITIATE_CHECKOUT_SUCCES,
      });
    } else {
      dispatch({
        type: userActionTypes.INITIATE_CHECKOUT_FAILURE,
      });
    }
  }
};

export const paymentCheck = (token) => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.PAYMENT_CHECK_REQUEST,
  });

  const tokenBody = { token: token };

  const result = await sendCheckPayment(tokenBody);

  if (result.status === 200) {
    dispatch({
      type: userActionTypes.PAYMENT_CHECK_SUCCES,
      payload: result.data,
    });
  } else if (result.status === 202) {
    dispatch({
      type: userActionTypes.PAYMENT_CHECK_FAILURE_IBAN,
    });
  } else {
    dispatch({
      type: userActionTypes.PAYMENT_CHECK_FAILURE,
    });
  }
};

export const resetCheckCount = () => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.RESET_CHECK_COUNT,
  });
};

export const restoreCart = (token) => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.RESTORE_CART_REQUEST,
  });

  const result = await fetchLostCart(token);

  if (result.status === 200) {
    dispatch({
      type: userActionTypes.RESTORE_CART_SUCCES,
      payload: result.data,
    });
  } else {
    dispatch({
      type: userActionTypes.RESTORE_CART_FAILURE,
    });
  }
};

export const changePhoneTab = (body) => (dispatch, getState) => {
  dispatch({
    type: userActionTypes.POST_PHONE_TAB,
    payload: body,
  });
};

//SEND TRACKING ACTIONS

export const paymentSucces = (neUser) => async (dispatch, getState) => {
  dispatch({
    type: userActionTypes.PAYMENT_SUCCES,
  });
};
