import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createBackup, postNewUser } from "../../../../redux/user/user.actions";

const HandleButton = () => {
  const history = useHistory();
  const newUser = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleClick = async () => {
    dispatch(postNewUser({ addressPopup: false }));

    dispatch(createBackup(newUser));

    history.push("/afronden");
  };

  return (
    <Button className="btn" onClick={() => handleClick()}>
      Ja, opslaan
    </Button>
  );
};

export default HandleButton;

const Button = styled.div`
  background-color: white;
`;
