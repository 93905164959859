import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { ArrowRightShort } from "react-bootstrap-icons";
import "moment/min/locales";
import { initiateCheckout, postNewUser } from "../../redux/user/user.actions";
import Spinner from "react-bootstrap/Spinner";
import ReactPixel from "react-facebook-pixel";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const HandleButton = ({ terms }) => {
  const dispatch = useDispatch();
  const newUser = useSelector((state) => state.user);
  const sendDataToGTM = useGTMDispatch();

  const handleClick = () => {
    dispatch(
      postNewUser({
        isLoading: true,
      })
    );

    sendDataToGTM({ ecommerce: null });
    sendDataToGTM({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 3 },
          products: [
            {
              name: "Wijde Boxershort",
              id: 4132,
              price: newUser.total,
              brand: "Underdog",
              variant: String(newUser.line_items[0].variation_id),
              quantity: 1,
            },
          ],
        },
      },
    });

    ReactPixel.track(
      "InitiateCheckout",
      {
        value: newUser.total,
        currency: "EUR",
        fn: newUser.first_name,
        ln: newUser.last_name,
        em: newUser.email,
        ct: newUser.city,
        zp: newUser.postcode,
        country: newUser.country,
        external_id: newUser.tracking?.external_id || null,
        fbp: newUser.tracking?.fbp || null,
        fbc: newUser.tracking?.fbc || null,
      },
      { eventID: 300 }
    );

    dispatch(initiateCheckout(newUser));
  };

  return (
    <div className="text-center">
      {newUser.isLoading === false && terms === true ? (
        <Button
          className="btn btn-primary mt-4 checkout-button"
          onClick={handleClick}
        >
          Afronden <ArrowRightShort />
        </Button>
      ) : newUser.isLoading === false && terms === false ? (
        <Button
          className="btn btn-primary mt-4 checkout-button"
          onClick={handleClick}
          disabled
        >
          Afronden <ArrowRightShort />
        </Button>
      ) : (
        <Button className="btn btn-primary mt-4">
          <StyledSpinner animation="border" variant="white" />
        </Button>
      )}
    </div>
  );
};

export default HandleButton;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const Button = styled.button`
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
`;
